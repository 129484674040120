import styled from 'styled-components/macro';

const Container = styled.div`
  margin: 0 auto;

  width: 100%;
  max-width: 960px;
  box-sizing: border-box;

  @media screen and (max-width: 960px) {
    padding: 0 20px;
    max-width: 680px;
  }

  @media screen and (max-width: 640px) {
    padding: 0 15px;
  }

  @media screen and (max-width: 320px) {
    padding: 0 10px;
  }
`;

export default Container;
