import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';

import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import './styles/fonts.css';
import App from './containers/App';

import 'i18n';
import client from './graphql/client';
import * as serviceWorker from './serviceWorker';

import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { env } from 'globalVariables';

const gtmConfig: TagManagerArgs = {
  gtmId: env.gmtId,
};

TagManager.initialize(gtmConfig);

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
