export type VariablesTypes = {
  defaultAnimationDuration: string;
  contentElementMarginBottom: string;
  fontSizeSm: string;
};

const variables: VariablesTypes = {
  defaultAnimationDuration: '300ms',
  contentElementMarginBottom: '1.2em',
  fontSizeSm: '0.75em',
};

export default variables;
