import * as React from 'react';
import Spinner from 'react-bootstrap/Spinner';

import * as Sty from './LoadingOverly.styles';

type Props = {
  size?: 'sm' | 'lg';
  fixed?: boolean;
};

const LoadingOverly: React.FC<Props> = ({ size, fixed }) => {
  return (
    <Sty.Overly fixed={fixed}>
      <Spinner
        size={size === 'sm' ? 'sm' : undefined}
        animation="border"
        role="status"
        aria-hidden="true"
      />
    </Sty.Overly>
  );
};

export default LoadingOverly;
