import * as React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Button from 'components/Button';
import Form from 'components/Form';

import * as Sty from './SignInForm.styles';

interface FormState {
  identity: string;
  password: string;
}

const initialValues: FormState = {
  identity: '',
  password: '',
};

const ERROR_MESSAGE = 'Hoppsan! Wrong email and/or password';

const validationSchema = Yup.object().shape({
  identity: Yup.string()
    .email("Can you please double-check that address, it doesn't seem valid")
    .required("Can you please double-check that address, it doesn't seem valid"),
  password: Yup.string().required('Passwrod field is required'),
});

type Props = {
  onSubmit: (sets: FormState) => void;
  isError: boolean;
  isLoading: boolean;
};

const SignInForm: React.FC<Props> = ({ isError, isLoading, onSubmit }) => {
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    resetForm,
    submitCount,
    handleChange,
    handleSubmit,
    handleBlur,
  } = useFormik({ initialValues, onSubmit, validationSchema });

  React.useEffect(() => {
    if (isError) {
      resetForm();
    }
  }, [isError, resetForm]);

  const handleFormSubmit = React.useCallback(
    (ev: React.FormEvent<HTMLElement>) => {
      handleSubmit(ev as React.FormEvent<HTMLFormElement>);
    },
    [handleSubmit],
  );

  return (
    <Form noValidate onSubmit={handleFormSubmit}>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>E-mail address</Form.Label>
        <Form.Control
          name="identity"
          type="text"
          size="lg"
          placeholder="your@email.com"
          value={values.identity}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={!!errors.identity && touched.identity}
        />
        <Form.Control.Feedback type="invalid">{errors.identity}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Form.Group} controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control
          name="password"
          placeholder="Password"
          type="password"
          size="lg"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={!!errors.password && touched.password}
        />
        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
      </Form.Group>

      {isError && <Sty.ErrorMessage>{ERROR_MESSAGE}</Sty.ErrorMessage>}

      <Sty.Center>
        <Button
          type="submit"
          size="lg"
          variant="success"
          loading={isLoading}
          disabled={Boolean(submitCount) || !(isValid && dirty)}
        >
          Sign In
        </Button>
      </Sty.Center>
    </Form>
  );
};

export default SignInForm;
