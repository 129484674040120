import * as React from 'react';
import { gql } from 'apollo-boost';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { FaSignInAlt } from 'react-icons/fa';
import { IoMdPersonAdd } from 'react-icons/io';
import { RiLockPasswordLine } from 'react-icons/ri';
import coffeeImage from 'assets/images/coffee.png';

import { AccountRoles } from 'models/user';

import Popover from 'components/Popover';
import * as Sty from './UserMenuPopover.styles';
import Button from '../../../components/Button';
import { useHistory } from 'react-router-dom';
import { SET_AUTHORIZED } from '../../SignIn';

const CLEAR_ACCOUNT = gql`
  mutation {
    clearAccount @client
  }
`;

const GET_ACCOUNT = gql`
  query {
    account @client {
      id
      role
    }
  }
`;

type Props = {
  handleAddUserClickCb: () => void;
  handleChangePasswordClickCb: () => void;
  children: React.ReactChild;
};

const UserMenuPopover: React.FC<Props> = ({
  handleAddUserClickCb,
  handleChangePasswordClickCb,
  children,
}) => {
  const history = useHistory();
  const popoverRef = React.useRef<any>(null);
  const [setAutorized] = useMutation(SET_AUTHORIZED);
  const [clearAccount] = useMutation(CLEAR_ACCOUNT);
  const getAccount = useQuery(GET_ACCOUNT);
  const currentUser = JSON.parse(localStorage.getItem('account')!);

  const handleAddUser = React.useCallback(() => {
    popoverRef?.current.hide();
    handleAddUserClickCb();
  }, [handleAddUserClickCb]);

  const handleChnagePassword = React.useCallback(() => {
    popoverRef?.current.hide();
    handleChangePasswordClickCb();
  }, [handleChangePasswordClickCb]);

  const handleMyCoffee = () => {
    popoverRef?.current.hide();
    history.push('/my-coffee');
  };

  const isAdmin = React.useMemo(() => {
    return getAccount.data.account?.role;
  }, [getAccount]);

  const showAddUserButton = React.useMemo(() => {
    return (
      isAdmin === AccountRoles.admin && (
        <Sty.OptionsItem as={Button} onClick={handleAddUser}>
          Add user <IoMdPersonAdd size=".9em" color="#26a9e0" />
        </Sty.OptionsItem>
      )
    );
  }, [isAdmin, handleAddUser]);

  const handleLogout = React.useCallback(async () => {
    await clearAccount();
    await setAutorized({ variables: { isAuthorized: false } });
    localStorage.removeItem('token');
    localStorage.removeItem('account');
    history.push('/login');
  }, [setAutorized, clearAccount, history]);

  return (
    <Popover
      id="user-menu-popover"
      ref={popoverRef}
      place="bottom-end"
      content={
        <Sty.Wrapper>
          {!!currentUser.iframe && (
            <Sty.OptionsItem as={Button} variant="link" onClick={handleMyCoffee}>
              My Coffee
              <img
                width="18px"
                src={coffeeImage}
                alt="My Coffee"
                style={{
                  marginLeft: '.5em',
                  marginBottom: '3px',
                }}
              />
            </Sty.OptionsItem>
          )}
          {showAddUserButton}
          <Sty.OptionsItem as={Button} variant="link" onClick={handleChnagePassword}>
            Change password <RiLockPasswordLine size=".9em" color="#26a9e0" />
          </Sty.OptionsItem>
          <Sty.OptionsItem as={Button} variant="link" onClick={handleLogout}>
            Log out <FaSignInAlt size=".9em" color="#26a9e0" />
          </Sty.OptionsItem>
        </Sty.Wrapper>
      }
    >
      {children}
    </Popover>
  );
};

export default UserMenuPopover;
