import styled from 'styled-components/macro';

const headerHeight = 60;
const smHeaderHeight = 40;

export const FixedHeader = styled.div`
  display: flex;
  width: 100%;
  height: ${headerHeight}px;
  position: fixed;
  top: 0;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 960px) {
    height: ${smHeaderHeight}px;
  }
`;

export const HeaderGhost = styled.div`
  width: 100%;
  height: ${headerHeight}px;
  position: relative;
  z-index: 0;

  @media screen and (max-width: 960px) {
    height: ${smHeaderHeight}px;
  }
`;

export const NavList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 100%;

  @media screen and (max-width: 960px) {
    & svg {
      width: 20px;
      height: 20px;
    }
  }

  @media screen and (max-width: 640px) {
    & svg {
      width: 20px;
      height: 20px;
    }

    & a > span > svg#signIn {
      width: 13px;
      height: 14px;
    }
  }
`;

export const Title = styled.span`
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  font-family: Signika, sans-serif;
  color: #4c5365;
  white-space: nowrap;

  @media screen and (max-width: 960px) {
    font-size: 22px;
  }
`;

export const SignInButton = styled.button`
  font-size: 1.4em;
  color: #4c5365;
  cursor: pointer;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    color: #26a9e0;
    text-decoration: none;
  }

  svg {
    margin-left: 0.5em;
  }

  @media screen and (max-width: 960px) {
    justify-content: flex-end;
    font-size: 1.1em;
  }

  @media screen and (max-width: 640px) {
    font-size: 0.91em;
  }
`;

export const HomeButton = styled.button`
  position: relative;
  height: 40px;

  img {
    height: 100%;
  }

  @media screen and (max-width: 320px) {
    padding: 0;
    font-size: 0.8em;
  }
`;
