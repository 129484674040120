import styled from 'styled-components/macro';

const headerHeight = 60;

export const SecondHeader = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  height: ${headerHeight}px;
  padding: 10px 0;
  background-color: #4e5362;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;

  h1 {
    margin: 0;
    color: #fff;
    line-height: 40px;
    font-size: 2em;
  }
`;
