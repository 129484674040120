import styled from 'styled-components/macro';
import { lighten } from 'polished';

export const Wrapper = styled.div`
  position: relative;
  width: 225px;
  padding: 0.7em;
  border-radius: 4px;
  overflow: hidden;
`;

export const OptionsItem = styled.button`
  display: flex;
  width: 100%;
  line-height: 30px;
  color: ${props => props.theme.colors.skyBlue};
  font-size: 1.4em;
  cursor: pointer;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    color: ${props => props.theme.colors.skyBlue};
    background-color: ${props => lighten(0.02, props.theme.colors.gradientLightGrey)};
    text-decoration: none;
  }

  svg {
    margin-left: 0.5em;
  }

  @media screen and (max-width: 960px) {
    font-size: 1.1em;
  }

  @media screen and (max-width: 640px) {
    font-size: 0.91em;
  }
`;
