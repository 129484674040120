import styled from 'styled-components/macro';

export const ErrorMessage = styled.p`
  color: #dc3545;
  font-size: 1.3em;
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
`;
