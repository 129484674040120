import styled, { css } from 'styled-components/macro';

type ButtonProps = {
  variant?: string;
  color?: string;
  background?: string;
  reportButtonStyles?: boolean;
};

export const Button = styled.div<ButtonProps>`
  position: relative;
  /* border-width: 0; */

  display: inline-flex;
  align-items: center;
  justify-content: center;

  .icon {
    margin-right: 5px;
    line-height: 1;
  }

  & > * {
    pointer-events: none;
  }

  &,
  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    border-radius: ${props => !props.variant && 0};
    color: ${props => {
      if (props.color) {
        return props.color;
      }

      return !props.variant && '#333';
    }};
    background-color: ${props => {
      if (props.background) {
        return props.background;
      }

      return !props.variant && 'transparent';
    }};
    border-color: ${props => props.background && '#ff486c'}};
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 220, 220, 0.5);
  }

  &:disabled {
    cursor: default;
  }

  ${props =>
    props.reportButtonStyles &&
    css`
      height: 45px;
      min-width: 150px;
      margin: 0 18px !important;
      padding: 4px 16px;

      @media (max-width: 1096px) {
        height: 36px;
        min-width: 105px;
        margin: 8px 5px !important;
        padding: 4px 8px;
      }
    `};
`;

export const LoadingWrap = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
`;
