import * as React from 'react';
import BootstrapButton, { ButtonProps } from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

import LoadingOverly from 'components/LoadingOverly';

import * as Sty from './Button.styles';

interface Props extends ButtonProps {
  id?: string;
  loading?: boolean;
  reportButtonStyles?: boolean;
  background?: string;
  to?: string;
  href?: string;
  target?: '_blank' | '_self' | '_parent' | '_top' | 'framename';
  color?: string;
  icon?: React.ReactNode;
  style?: any;
  className?: string;
  onClick?: (ev: React.MouseEvent) => void;
  children: React.ReactNode | string;
}

const LinkButton = ({ children, ...rest }: any) => (
  <BootstrapButton as={rest.href ? 'a' : Link} {...rest}>
    {children}
  </BootstrapButton>
);

const Button: React.ForwardRefRenderFunction<any, Props> = (
  {
    id,
    loading = false,
    background = null,
    reportButtonStyles = false,
    children,
    onClick = () => null,
    disabled = false,
    size = 'sm',
    variant = null,
    color,
    icon,
    to,
    href,
    target,
    ...props
  },
  ref,
) => {
  const handleClick = React.useCallback(
    (ev: React.MouseEvent) => {
      if (loading) return;

      onClick(ev);
    },
    [loading, onClick],
  );

  return (
    <Sty.Button
      {...props}
      as={to || href ? LinkButton : BootstrapButton}
      to={to}
      href={href}
      target={target}
      background={background}
      reportButtonStyles={reportButtonStyles}
      id={id}
      ref={ref}
      size={size}
      variant={variant}
      color={color}
      onClick={handleClick}
      disabled={disabled || loading}
    >
      {icon && <span className="icon">{icon}</span>}
      {children}
      {loading && (
        <Sty.LoadingWrap>
          <LoadingOverly size={size} />
        </Sty.LoadingWrap>
      )}
    </Sty.Button>
  );
};

export default React.forwardRef(Button);
