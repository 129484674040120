// tslint:disable: jsx-no-lambda
import * as React from 'react';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import ToastLib from 'react-bootstrap/Toast';
import * as Sty from './Toast.styles';

type PropTypes = {
  toast?: {
    text: string;
    type?: string;
    header?: string;
  };
};

const Toast: React.FC<PropTypes> = ({ toast }) => {
  const [localToasts, updateLocalToasts] = React.useState<
    { timeStamp: number; text: string; type?: string; header?: string }[]
  >([]);
  React.useEffect(() => {
    if (toast) {
      updateLocalToasts(prevToasts =>
        concat(prevToasts, {
          ...toast,
          timeStamp: Date.now(),
        }),
      );
    }
  }, [toast]);

  const handleClose = (timeStamp: any) => {
    updateLocalToasts(
      // @ts-ignore
      filter(localToasts, localT => localT.timeStamp !== timeStamp),
    );
  };

  return (
    <Sty.ToastWrapper
      aria-live="polite"
      aria-atomic="true"
      isEmpty={localToasts.length === 0}
    >
      {localToasts.map((localT: any) => {
        if (!localT?.header) {
          return null;
        }

        return (
          <ToastLib key={localT.timeStamp} onClose={() => handleClose(localT.timeStamp)}>
            <Sty.ToastHeader as={ToastLib.Header} hasError={localT.isError}>
              <strong className="mr-auto">{localT.header}</strong>
            </Sty.ToastHeader>
            <Sty.ToastBody as={ToastLib.Body} hasError={localT.isError}>
              {localT.text}
            </Sty.ToastBody>
          </ToastLib>
        );
      })}
    </Sty.ToastWrapper>
  );
};

export default Toast;
