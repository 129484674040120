import * as React from 'react';
import { Switch, Route, Redirect, RouteProps } from 'react-router-dom';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { Helmet } from 'react-helmet';

// const LoadingOverly = React.lazy(() => import('components/LoadingOverly'));
const SignIn = React.lazy(() => import('containers/SignIn'));
const Home = React.lazy(() => import('containers/Home'));
const NotFound = React.lazy(() => import('containers/NotFound'));
const Assessment = React.lazy(() => import('containers/Assessment'));
const Calls = React.lazy(() => import('containers/Calls'));
const ReportPreview = React.lazy(() => import('containers/ReportPreview'));
const CustomerContainer = React.lazy(() => import('containers/CustomerContainer'));

const GET_AUTHORIZED = gql`
  query IsAuthorized {
    isAuthorized @client
  }
`;

interface PrivatRouteProp extends RouteProps {
  allow: boolean;
  redirect?: string;
}

const PrivateRoute = ({ children, allow, redirect, ...rest }: PrivatRouteProp) => {
  const routeComponent = () =>
    allow ? children : <Redirect to={{ pathname: redirect || '/login' }} />;
  return <Route {...rest} render={routeComponent} />;
};

const Routes = () => {
  const { data } = useQuery(GET_AUTHORIZED);
  const currentUser = JSON.parse(localStorage.getItem('account') || 'null');

  return (
    <Switch>
      <PrivateRoute path="/login" redirect="/" allow={!data.isAuthorized}>
        <React.Suspense fallback={''}>
          <SignIn />
        </React.Suspense>
      </PrivateRoute>
      <PrivateRoute exact path="/" allow={data.isAuthorized}>
        <React.Suspense fallback={''}>
          <Helmet>
            <title>Orders: Go Monday</title>
          </Helmet>
          <Home />
        </React.Suspense>
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/clients"
        allow={
          data.isAuthorized &&
          currentUser?.clientsPageAvailable === true &&
          currentUser?.role === 'admin'
        }
      >
        <React.Suspense fallback={''}>
          <Helmet>
            <title>Clients: Go Monday</title>
          </Helmet>
          <Home />
        </React.Suspense>
      </PrivateRoute>
      <PrivateRoute exact path="/my-coffee" allow={data.isAuthorized}>
        <React.Suspense fallback={''}>
          <Helmet>
            <title>My Coffee: Go Monday</title>
          </Helmet>
          <Home />
        </React.Suspense>
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/clients/:id"
        allow={data.isAuthorized && currentUser?.clientsPageAvailable === true}
      >
        <React.Suspense fallback={''}>
          <Helmet>
            <title>Client: Go Monday</title>
          </Helmet>
          <CustomerContainer />
        </React.Suspense>
      </PrivateRoute>
      <PrivateRoute path="/assessments/:id" allow={data.isAuthorized}>
        <React.Suspense fallback={''}>
          <Helmet>
            <title>Assessments: Go Monday</title>
          </Helmet>
          <Assessment />
        </React.Suspense>
      </PrivateRoute>
      <PrivateRoute path="/handleCall/:id" allow={data.isAuthorized}>
        <React.Suspense fallback={''}>
          <Helmet>
            <title>Handle Call: Go Monday</title>
          </Helmet>
          <Calls />
        </React.Suspense>
      </PrivateRoute>
      <PrivateRoute path="/report-preview/:id" allow={data.isAuthorized}>
        <React.Suspense fallback={''}>
          <Helmet>
            <title>Report Preview: Go Monday</title>
          </Helmet>
          <ReportPreview />
        </React.Suspense>
      </PrivateRoute>
      <Route path="*">
        <React.Suspense fallback={''}>
          <Helmet>
            <title>Not Found: Go Monday</title>
          </Helmet>
          <NotFound />
        </React.Suspense>
      </Route>
    </Switch>
  );
};

export default Routes;
