import styled from 'styled-components/macro';

const headerHeight = 65;
const smHeaderHeight = 45;

export const ToastWrapper = styled.div<{ isEmpty: boolean }>`
  display: ${(props: any) => (props?.isEmpty ? 'none' : 'block')};
  position: fixed;
  right: 0;
  top: ${smHeaderHeight}px;
  min-height: 110px;
  min-width: 200px;

  @media screen and (min-width: 960px) {
    top: ${headerHeight}px;
  }
`;

export const ToastHeader = styled.div<{ hasError: boolean }>`
  height: 30px;
  background-color: ${(props: any) =>
    props?.hasError
      ? props.theme.colors.redCommentBg
      : props.theme.colors.greenCommentBg};

  > button {
    margin-bottom: 0 !important;
  }
`;

export const ToastBody = styled.div<{ hasError: boolean }>`
  background-color: ${(props: any) =>
    props?.hasError
      ? props.theme.colors.redCommentBg
      : props.theme.colors.greenCommentBg};
`;
