import * as React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Form from 'components/Form';
import Button from 'components/Button';

import * as Sty from './ChangePasswordForm.styles';
import { PASSWORD_COMPLEXITY_REGEXP } from 'models/user';

interface FormState {
  oldPassword: string;
  password: string;
  passwordConfirm: string;
}

const initialValues: FormState = {
  oldPassword: '',
  password: '',
  passwordConfirm: '',
};

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Old password field is required'),
  password: Yup.string()
    .required('Password field is required')
    .min(6, 'Password must be at least 6 characters')
    .max(24, 'Password must be at most 24 characters')
    .matches(
      PASSWORD_COMPLEXITY_REGEXP,
      'The password needs to contain at least one number and one letter.',
    )
    .test(
      'password',
      'New password should not be the same as the old one',
      function (value) {
        return value !== this.parent.oldPassword;
      },
    ),
  passwordConfirm: Yup.string()
    .required('Confirm password field is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});

type Props = {
  onSubmit: (sets: FormState) => void;
  isError: boolean;
  isLoading: boolean;
  handleCloseClick: () => void;
};

const ChangePasswordForm: React.FC<Props> = ({
  isError,
  isLoading,
  onSubmit,
  handleCloseClick,
}) => {
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    resetForm,
    handleChange,
    handleSubmit,
    handleBlur,
  } = useFormik({ initialValues, onSubmit, validationSchema });

  React.useEffect(() => {
    if (isError) {
      resetForm();
    }
  }, [isError, resetForm]);

  const handleFormSubmit = React.useCallback(
    (ev: React.FormEvent<HTMLElement>) => {
      handleSubmit(ev as React.FormEvent<HTMLFormElement>);
    },
    [handleSubmit],
  );

  return (
    <Form noValidate onSubmit={handleFormSubmit}>
      <Form.Group as={Form.Group} controlId="formBasicOldPassword">
        <Form.Label>Old password</Form.Label>
        <Form.Control
          name="oldPassword"
          type="password"
          placeholder="Old password"
          value={values.oldPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={!!errors.oldPassword && touched.oldPassword}
          required
        />
        <Form.Control.Feedback type="invalid">{errors.oldPassword}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Form.Group} controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control
          name="password"
          type="password"
          placeholder="Password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={!!errors.password && touched.password}
          required
        />
        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Form.Group} controlId="formBasicPasswordConfirm">
        <Form.Label>Password</Form.Label>
        <Form.Control
          name="passwordConfirm"
          type="password"
          placeholder="Confirm password"
          value={values.passwordConfirm}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={
            !!errors.passwordConfirm &&
            touched.passwordConfirm &&
            errors.password !== errors.passwordConfirm
          }
          required
        />
        <Form.Control.Feedback type="invalid">
          {errors.passwordConfirm}
        </Form.Control.Feedback>
      </Form.Group>
      <Sty.ButtonsBlock>
        <Button variant="outline-info" onClick={handleCloseClick}>
          Cancel
        </Button>
        <Button
          variant="success"
          type="submit"
          loading={isLoading}
          disabled={!(isValid && dirty)}
        >
          Confirm
        </Button>
      </Sty.ButtonsBlock>
    </Form>
  );
};

export default ChangePasswordForm;
