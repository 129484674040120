// tslint:disable: jsx-no-multiline-js
import * as React from 'react';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { IoIosMenu } from 'react-icons/io';

import { getFullName } from 'utils/user';

import Button from 'components/Button';
import Container from 'components/Container';
import useScreenSize from 'hooks/useScrenSize';

import UserMenuPopover from './UserMenuPopover';

import logo from 'assets/images/logo.png';

import * as Sty from './Header.styles';
import AddUserModal from '../AddUserModal';
import ChangePasswordModal from 'containers/ChangePassword/ChangePasswordModal';

const GET_SESSION_INFO = gql`
  query {
    isAuthorized @client
    account @client {
      firstName
      lastName
    }
  }
`;

type Props = {
  onMessage: (newToast: any) => void;
};

const Header: React.FC<Props> = ({ onMessage }) => {
  const { data, loading, error } = useQuery(GET_SESSION_INFO);
  const [isAddUserModalShown, setIsAddUserModalShown] = React.useState(false);
  const [isChangePasswordModalShown, setIsChangePasswordModalShown] =
    React.useState(false);

  const isAuthorized = React.useMemo(() => {
    return !(loading || error) && data?.isAuthorized;
  }, [data, loading, error]);

  const userName = React.useMemo(() => {
    if (loading || error || !data?.account) {
      return null;
    }

    return getFullName(data.account);
  }, [data, loading, error]);

  const { width } = useScreenSize();

  const showUserName = React.useMemo(() => {
    return width && width > 640;
  }, [width]);

  const handleAddUserClickCb = () => {
    setIsAddUserModalShown(true);
  };
  const handleAddUserModalClose = () => {
    setIsAddUserModalShown(false);
  };

  const handleChangePasswordClickCb = () => {
    setIsChangePasswordModalShown(true);
  };

  const handleChangePasswordModalClose = () => {
    setIsChangePasswordModalShown(false);
  };

  const handleGoStartPage = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <Sty.FixedHeader>
        <Container>
          <Sty.NavList>
            {isAuthorized ? (
              <Sty.HomeButton
                to="/"
                variant="link"
                as={Button}
                onClick={handleGoStartPage}
              >
                <img src={logo} alt="GoMonday Coffee: Logo" />
              </Sty.HomeButton>
            ) : (
              <div />
            )}
            <Sty.Title>Coffee</Sty.Title>
            {isAuthorized && (
              <>
                <UserMenuPopover
                  handleAddUserClickCb={handleAddUserClickCb}
                  handleChangePasswordClickCb={handleChangePasswordClickCb}
                >
                  <Sty.SignInButton as={Button}>
                    {showUserName ? userName : ''}
                    <IoIosMenu size="2rem" color="#26a9e0" />
                  </Sty.SignInButton>
                </UserMenuPopover>
              </>
            )}
          </Sty.NavList>
        </Container>
      </Sty.FixedHeader>
      <Sty.HeaderGhost />

      <AddUserModal
        onClose={handleAddUserModalClose}
        isAddUserModalShown={isAddUserModalShown}
        onMessage={onMessage}
      />

      <ChangePasswordModal
        onClose={handleChangePasswordModalClose}
        isChangePasswordModalShown={isChangePasswordModalShown}
        onMessage={onMessage}
      />
    </>
  );
};

export default Header;
