// tslint:disable: jsx-no-multiline-js
import * as React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { gql } from 'apollo-boost';

import { handleApolloError } from 'services/errorService';
import { saveInLocalStorage } from 'services/browser';

import { localStorageKeys } from 'globalVariables';

import Container from 'components/Container';
import SecondHeader from 'components/SecondHeader';
import SignInForm from './SignInForm';

import * as Sty from './SignIn.styles';

export const SET_AUTHORIZED = gql`
  mutation ($isAuthorized: Boolean!) {
    setAuthorized(isAuthorized: $isAuthorized) @client
  }
`;

const SET_ACCOUNT = gql`
  mutation ($user: User!) {
    setAccount(user: $user) @client
  }
`;

const SIGN_IN = gql`
  mutation ($identity: String!, $password: String!) {
    auth(identity: $identity, password: $password) {
      token
      user {
        id
        firstName
        lastName
        role
        clientsPageAvailable
        bookCallAvailable
        iframe
      }
    }
  }
`;

const SignIn: React.FC = () => {
  const history = useHistory();

  const [setAuthorized] = useMutation(SET_AUTHORIZED);
  const [setAccount] = useMutation(SET_ACCOUNT);

  const [signIn, { loading, error }] = useMutation(SIGN_IN, {
    onCompleted: async ({ auth: { token, user } }) => {
      saveInLocalStorage(localStorageKeys.token, token);
      saveInLocalStorage(localStorageKeys.account, user);
      await setAccount({ variables: { user } });
      await setAuthorized({ variables: { isAuthorized: true } });
      history.push('/');
    },
    onError: err => handleApolloError(err),
  });

  const handleLogin = React.useCallback(
    sets => {
      signIn({ variables: sets });
    },
    [signIn],
  );

  return (
    <>
      <SecondHeader title="Sign in" />
      <Sty.SignIn>
        <Container>
          <SignInForm
            isError={Boolean(error)}
            isLoading={loading}
            onSubmit={handleLogin}
          />
        </Container>
      </Sty.SignIn>
    </>
  );
};

export default SignIn;
