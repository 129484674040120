export default {
  brandName: 'Go Monday',
  domain: 'gomonday.se',
  domainBrand: 'GoMonday.se',
  contactEmailName: 'hello',
  raterAppName: 'Coffee',
  'edition.name.PRO': 'Erfaren',
  'edition.name.YOUNG_PRO': 'Ny i yrkeslivet',
  'edition.name.EXEC': 'Chef',
  'edition.name.CONSULT': 'Konsult',
  'edition.name.ACADEMIA': 'Forskare',
  'category.title.12': 'Redovisa resultat och skapa trovärdighet',
  'category.shortDesc.12':
    'Ett CV handlar i stor utsträckning om att bekräfta sin kompetens. Det är därför mycket viktigt att redovisa de resultat man uppnått.',
  'category.title.13': 'Översiktligt och korrekt',
  'category.shortDesc.13':
    'Ditt CV ska ge arbetsgivaren en snabb översikt över dina erfarenheter och meriter. Den ska vara korrekt skriven, kort och koncis. Det finns även ett antal faktorer i språk och upplägget som påverkar hur du uppfattas som person.',
  'category.title.14': 'Rikta och var relevant',
  'category.shortDesc.14':
    'I ett effektivt CV är varje del relevant för det aktuella jobbet och den aktuella arbetsgivaren. Det kräver att ditt CV har en tydlig röd linje och att alla delar pekar i samma riktning.',
  'category.title.7': 'Framhäv potential',
  'category.shortDesc.7':
    'Fokusera mer på din potential och vad du kan åstadkomma i framtiden, än på historia och vad du gjort tidigare.',
  'category.title.8': 'Fokusera på arbetsgivaren',
  'category.shortDesc.8':
    'Förklara varför du valt just den här arbetsgivaren och tjänsten.',
  'category.title.10': 'Redovisa resultat och skapa trovärdighet',
  'category.shortDesc.10':
    'Visa på ett trovärdigt sätt att du har kunskapen och förmågan att åstadkomma resultat för arbetsgivaren.',
  'category.title.11': 'Aktivt, kort och korrekt',
  'category.shortDesc.11':
    'En dåligt skriven ansökan placerar dig som sökande ofta direkt längst ner i högen.',
  'category.title.16': 'Rikta och var relevant',
  'category.shortDesc.16':
    'När en person har hittat till din profil så behöver hen snabbt förstå vem du är, vad du vill och varför hen ska kontakta just dig. Att visa en tydlig riktning och röd tråd är därför A och O. För att lyckas med det behöver du dels bestämma din riktning och dels tydliggöra den i profilen. I granskningen hjälper jobbexperten dig att lyckas med det sistnämnda. ',
  'category.title.17': 'Skapa effekt och bygg räckvidd',
  'category.shortDesc.17':
    'Det räcker ju inte bara med att ha en intressant profil för att bli kontaktad på LinkedIn. Du måste också arbeta för att personer ska hitta till den. Här handlar det dels om att nå ut till så många som möjligt inom ditt yrkesområde rent tekniskt. Dels handlar det om att få personer att få upp ögonen för just din profil så att de besöker den och läser vidare om dig.',
  'category.title.18': 'Översiktligt och korrekt',
  'category.shortDesc.18':
    'Din LinkedIn-profil ska vara korrekt skriven och lätt att ta till sig för att du ska ge ett bra intryck till de som hittar till den. För att bli hittad är det också viktigt att profilen är komplett och att du fyllt i rätt uppgifter på rätt plats.',
  'category.title.20': 'Redovisa resultat och skapa trovärdighet',
  'category.shortDesc.20':
    'För att man ska förstå vad du har gjort tidigare i ditt jobbliv så behöver du beskriva det ordentligt. Även om din framtid är viktig, som du ju fokuserar på i din sammanfattning, så är din historia också viktig och du behöver ge en trovärdig bild av din bakgrund. ',
  'category.title.21': 'Visa att du kommer trivas',
  'category.shortDesc.21':
    'Frågorna i den här kategorin handlar i första hand om en övergripande matching kulturellt med organisationen. Rekryteraren vill försäkra sig om att du fungerar med teamet och att förutsättningarna finns för att du ska kunna trivas och utvecklas.',
  'category.title.22': 'Förklara att du kan göra ett bra jobb',
  'category.shortDesc.22':
    'De här frågorna kretsar i första hand kring kompetensen för jobbet. En erfaren rekryterare söker efter överförbara kompetenser, det vill säga kompetenser du utvecklat tidigare men kan tillämpa i ett nytt sammanhang. Tänk på att bestämma dig för några huvudbudskap och återkomma till dem vid flera tillfällen under intervjun. Vad är de 2-3 viktigaste skälen till du är rätt person för det här jobbet?',
  'category.title.23': 'Framhäv varför du vill jobba här',
  'category.shortDesc.23':
    'Kompetens är egentligen allting som gör att vi är bra på att utföra något. Från det perspektivet är vilja och intresse kompetenser. Utöver de formella meriterna och erfarenheterna, och utöver dina personliga egenskaper och styrkor, så är det viktigt att du ger en bild av varför det här jobbet är rätt jobb för dig. Var specifik i dina förklaringar till varför du söker den här tjänsten och vad som lockar dig till den här organisationen.',
  'category.title.24': 'Framhäv talang och var relevant',
  'category.shortDesc.24':
    'I en effektiv portfolio är varje del relevant för den typ av uppdrag du söker och framhäver din talang på ett sätt som får dig att sticka ut.',
  'category.title.25': 'Projekt och resultat',
  'category.shortDesc.25':
    'En portfolio handlar i stor utsträckning om att bekräfta att du kan åstadkomma resultat i de projekt du deltar i. Det är därför mycket viktigt att dina beskrivningar är trovärdiga och ger en bra bild av tidigare projekt.',
  'category.title.26': 'Utformning och layout',
  'category.shortDesc.26':
    'En portfolio måste ha ett starkt innehåll. Men din portfolio är också i sig ett prov på bland annat din visuella förmåga och noggrannhet. Den ska vara korrekt skriven, ha bra layout och gärna visa en visuell identitet.',
  'category.title.27': 'Sätt dina budskap',
  'order.INTERVIEW_TRAINING.question.qIntroduceYourself': 'Berätta lite om dig själv!',
  'order.INTERVIEW_TRAINING.question.qCaltureWorkspace':
    'Vilken kultur skulle du vilja se på en arbetsplats?',
  'order.INTERVIEW_TRAINING.question.qCollaboration':
    'Berätta om en situation då samarbete hjälpt dig lösa en uppgift. Vilken roll tog du i gruppen?',
  'order.INTERVIEW_TRAINING.question.qImportantEnjoyWork':
    'Vad är viktigast för att du ska trivas med ditt arbete? Ge gärna några exempel.',
  'order.INTERVIEW_TRAINING.question.qImportantFeatures':
    'Vad skulle du säga är dina viktigaste egenskaper för den här rollen?',
  'order.INTERVIEW_TRAINING.question.qWeaknesses': 'Vad har du för svaga sidor?',
  'order.INTERVIEW_TRAINING.question.qLearned':
    'Vad har du dragit för lärdomar under dina senaste jobb som du tar med dig till oss?',
  'order.INTERVIEW_TRAINING.question.qWorkForUs': 'Varför vill du jobba för oss?',
  'order.INTERVIEW_TRAINING.question.qKnowAboutUs':
    'Vad ser du skiljer ett jobb hos oss från andra organisationer?',
  'order.INTERVIEW_TRAINING.question.qSeeYourself': 'Var ser du dig själv om fem år?',
  'order.INTERVIEW_TRAINING.question.qAboutUs':
    'Har du några frågor om oss eller om tjänsten?',
  'wellDone.comment.12':
    'Bra jobbat i denna kategori! Du har lyckats beskriva dina erfarenheter på ett bra sätt.',
  'wellDone.comment.13':
    'Bra jobbat på detta område! Du har ett snyggt och överskådligt CV.',
  'wellDone.comment.14':
    'Bra jobbat på detta område! Ditt CV är riktat till den tjänst du söker på ett bra sätt.',
  'wellDone.comment.7':
    'Bra jobbat i denna kategori! Du har lyckats framhäva din potential på ett bra sätt.',
  'wellDone.comment.8':
    'Bra jobbat på detta område! Du visar att du är påläst om arbetsgivaren och varför du passar för tjänsten.',
  'wellDone.comment.10':
    'Bra jobbat på detta område! Du framhäver dina egenskaper på ett bra och trovärdigt vis.',
  'wellDone.comment.11':
    'Bra jobbat i denna kategori! Ditt brev är tydligt, snyggt och korrekt.',
  'wellDone.comment.16':
    'Bra jobbat på detta område! Din profil har en tydlig inriktning och du är relevant för din målgrupp.',
  'wellDone.comment.17':
    'Bra jobbat på detta område! Fortsätt att bygga ditt nätverk och var aktiv på LinkedIn.',
  'wellDone.comment.18':
    'Bra jobbat på detta område! Du har en tydlig och korrekt profil.',
  'wellDone.comment.20':
    'Bra jobbat i denna kategori! Din profil ger ett trovärdigt intryck och du har beskrivit dina erfarenheter och utbildningar väl.',
  'wellDone.comment.21': 'Bra jobbat på detta område!',
  'wellDone.comment.22': 'Bra jobbat på detta område!',
  'wellDone.comment.23': 'Bra jobbat på detta område!',
  'wellDone.comment.24':
    'Bra jobbat på det här området! Det är tydligt var din talang ligger och du har riktat din portfolio bra.',
  'wellDone.comment.25':
    'Bra jobbat på det här området! Du visar upp dina erfarenheter på ett bra och trovärdigt vis så att man förstår vad du har åstadkommit.',
  'wellDone.comment.26':
    'Du har en stilren och överskådlig layout, vilket är mycket bra! Utformningen av din portfolio behöver inte vara överdesignad, men din portfolio ska vara snygg och visa att du har en stilistisk förmåga - och de visar du!',
  'report.title': 'Din bedömningsrapport',
  'report.subtitle': 'Bedömning',
  'report.overall': 'Övergripande kommentar',
  'report.was_created': 'Feedbacken utfördes av en jobbexpert i: ',
  'report.understand': 'Förstå din Go-Score',
  'report.open_link': 'Se: ',
  'report.link': 'öppna dokument i ny flik',
  'report.orderCreationDate.label': 'Skapad',
  'report.tabName.CV_REVIEW': 'Bedömning av CV',
  'report.tabName.COVER_LETTER_REVIEW': 'Bedömning av personligt brev',
  'report.tabName.LINKEDIN_PROFILE_REVIEW': 'Bedömning av LinkedIn',
  'report.tabName.PORTFOLIO_REVIEW': 'Bedöming av portfolio',
  'report.tabName.INTERVIEW_TRAINING': 'Interview Training',
  'report.tabNameSmallScreen.CV_REVIEW': 'CV',
  'report.tabNameSmallScreen.COVER_LETTER_REVIEW': 'Personligt brev',
  'report.tabNameSmallScreen.PORTFOLIO_REVIEW': 'Portfolio',
  'report.tabNameSmallScreen.LINKEDIN_PROFILE_REVIEW': 'LinkedIn',
  'report.tabNameSmallScreen.INTERVIEW_TRAINING': 'Interview Training',
  'report.tabNameSmallScreen.LINKEDIN_PROFILE_CONSULT': 'LinkedIn',
  'report.tabNameSmallScreen.LINKEDIN_PROFILE_IMPRESSION': 'LinkedIn',
  'report.tabNameSmallScreen.CV_REVIEW_CONSULT': 'CV Konsultprofil',
  'report.unorderedAssessment.text':
    'Du har inte beställt den är produkten. Du behöver göra en ny beställning för att komma åt den här produkten.',
  'report.unorderedAssessment.orderBtn.text': 'Beställ en ny bedömning',
  'report.summary.title': 'Resultat av din bedömning',
  'report.summary.documentLink.text': 'Original',
  'report.summary.score.label': 'Din C-Score',
  'report.summary.score.bar.label.weak': 'Svag',
  'report.summary.score.bar.label.good': 'Bra',
  'report.summary.score.bar.label.excellent': 'Utmärkt',
  'report.summary.understandYourScore.title': 'Förstå din C-Score',
  'report.summary.understandYourScore.cScoreExplanation.text':
    "C-Score mäter hur effektiv din ansökan är på en skala från 0 till 100. <a href='https://gomonday.se/om-oss/var-metod' target='_blank'>Läs mer</a>",
  'report.summary.understandYourScore.weak.text':
    'Mycket låg chans att komma på jobbintervju',
  'report.summary.understandYourScore.good.text':
    'Medelstora chanser att komma på jobbintervju',
  'report.summary.understandYourScore.excellent.text':
    'Mycket stor chans att komma på jobbintervju',
  'report.summary.CV_REVIEW.0':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av ditt CV. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste CV bedömningarna är <strong>{{averageScore}}</strong>.',
  'report.summary.CV_REVIEW.25':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av ditt CV. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste CV bedömningarna är <strong>{{averageScore}}</strong>.',
  'report.summary.CV_REVIEW.51':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av ditt CV. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste CV bedömningarna är <strong>{{averageScore}}</strong>.',
  'report.summary.CV_REVIEW.74':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av ditt CV. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste CV bedömningarna är <strong>{{averageScore}}</strong>.',
  'report.summary.CV_REVIEW.92':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av ditt CV. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste CV bedömningarna är <strong>{{averageScore}}</strong>.',
  'report.summary.COVER_LETTER_REVIEW.0':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av ditt personliga brev. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av personligt brev är <strong>{{averageScore}}</strong>.',
  'report.summary.COVER_LETTER_REVIEW.25':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av ditt personliga brev. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av personligt brev är <strong>{{averageScore}}</strong>.',
  'report.summary.COVER_LETTER_REVIEW.51':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av ditt personliga brev. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av personligt brev är <strong>{{averageScore}}</strong>.',
  'report.summary.COVER_LETTER_REVIEW.74':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av ditt personliga brev. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av personligt brev är <strong>{{averageScore}}</strong>.',
  'report.summary.COVER_LETTER_REVIEW.92':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av ditt personliga brev. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av personligt brev är <strong>{{averageScore}}</strong>.',
  'report.summary.PORTFOLIO_REVIEW.0':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av din portfolio. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av portfolio är <strong>{{averageScore}}</strong>.',
  'report.summary.PORTFOLIO_REVIEW.25':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av din portfolio. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av portfolio är <strong>{{averageScore}}</strong>.',
  'report.summary.PORTFOLIO_REVIEW.51':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av din portfolio. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av portfolio är <strong>{{averageScore}}</strong>.',
  'report.summary.PORTFOLIO_REVIEW.74':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av din portfolio. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av portfolio är <strong>{{averageScore}}</strong>.',
  'report.summary.PORTFOLIO_REVIEW.92':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av din portfolio. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av portfolio är <strong>{{averageScore}}</strong>.',
  'report.summary.LINKEDIN_PROFILE_REVIEW.0':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av din LinkedIn-profil. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av LinkedIn-profil är <strong>{{averageScore}}</strong>.',
  'report.summary.LINKEDIN_PROFILE_REVIEW.25':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av din LinkedIn-profil. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av LinkedIn-profil är <strong>{{averageScore}}</strong>.',
  'report.summary.LINKEDIN_PROFILE_REVIEW.51':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av din LinkedIn-profil. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av LinkedIn-profil är <strong>{{averageScore}}</strong>.',
  'report.summary.LINKEDIN_PROFILE_REVIEW.74':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av din LinkedIn-profil. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av LinkedIn-profil är <strong>{{averageScore}}</strong>.',
  'report.summary.LINKEDIN_PROFILE_REVIEW.92':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av din LinkedIn-profil. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av LinkedIn-profil är <strong>{{averageScore}}</strong>.',
  'report.summary.INTERVIEW_TRAINING.0':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av dina intervjusvar. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av intervjusvar är <strong>{{averageScore}}</strong>.',
  'report.summary.INTERVIEW_TRAINING.25':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av dina intervjusvar. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av intervjusvar är <strong>{{averageScore}}</strong>.',
  'report.summary.INTERVIEW_TRAINING.51':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av dina intervjusvar. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av intervjusvar är <strong>{{averageScore}}</strong>.',
  'report.summary.INTERVIEW_TRAINING.74':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av dina intervjusvar. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av intervjusvar är <strong>{{averageScore}}</strong>.',
  'report.summary.INTERVIEW_TRAINING.92':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av dina intervjusvar. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av intervjusvar är <strong>{{averageScore}}</strong>.',
  'report.summary.LINKEDIN_PROFILE_IMPRESSION.0':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av din LinkedIn-profil intryck. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av LinkedIn-profil intryck är <strong>{{averageScore}}</strong>.',
  'report.summary.LINKEDIN_PROFILE_IMPRESSION.25':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av din LinkedIn-profil intryck. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av LinkedIn-profil intryck är <strong>{{averageScore}}</strong>.',
  'report.summary.LINKEDIN_PROFILE_IMPRESSION.51':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av din LinkedIn-profil intryck. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av LinkedIn-profil intryck är <strong>{{averageScore}}</strong>.',
  'report.summary.LINKEDIN_PROFILE_IMPRESSION.74':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av din LinkedIn-profil intryck. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av LinkedIn-profil intryck är <strong>{{averageScore}}</strong>.',
  'report.summary.LINKEDIN_PROFILE_IMPRESSION.92':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av din LinkedIn-profil intryck. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av LinkedIn-profil intryck är <strong>{{averageScore}}</strong>.',
  'report.summary.CV_REVIEW_CONSULT.0':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av din konsultprofil eller CV. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av konsultprofil eller CV är <strong>{{averageScore}}</strong>.',
  'report.summary.CV_REVIEW_CONSULT.25':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av din konsultprofil eller CV. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av konsultprofil eller CV är <strong>{{averageScore}}</strong>.',
  'report.summary.CV_REVIEW_CONSULT.51':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av din konsultprofil eller CV. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av konsultprofil eller CV är <strong>{{averageScore}}</strong>.',
  'report.summary.CV_REVIEW_CONSULT.74':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av din konsultprofil eller CV. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av konsultprofil eller CV är <strong>{{averageScore}}</strong>.',
  'report.summary.CV_REVIEW_CONSULT.92':
    'Du fick resultatet <strong>{{score}} av 100</strong> möjliga poäng i vår bedömning av din konsultprofil eller CV. Genomsnittet för de {{nbLastAssessmentsToTakeIntoAccount}} senaste bedömningarna av konsultprofil eller CV är <strong>{{averageScore}}</strong>.',
  'report.question.INTERVIEW_TRAINING': 'Question',
  'report.answer.INTERVIEW_TRAINING': 'Answer',
  'report.analysis.title': 'Det här behöver du fokusera på',
  'report.analysis.INTERVIEW_TRAINING.title': 'Här är ditt underlag som vi tittat på',
  'report.analysis.explanation.text':
    'Vi har bedömt {{docLabel}} och identifierat vad du kan förbättra. Vi har sammanställt de åtgärder som är viktigast för att ta vidare {{docLabel}}. Läs igenom alla råd och börja sen förbättra {{docLabel}}.',
  'report.analysis.explanation.docLabel.CV_REVIEW': 'ditt CV',
  'report.analysis.explanation.docLabel.COVER_LETTER_REVIEW': 'ditt personliga brev',
  'report.analysis.explanation.docLabel.PORTFOLIO_REVIEW': 'din portfolio',
  'report.analysis.explanation.docLabel.LINKEDIN_PROFILE_REVIEW': 'din LinkedIn-profil',
  'report.analysis.explanation.docLabel.INTERVIEW_TRAINING': 'dina intervjusvar',
  'report.analysis.explanation.docLabel.LINKEDIN_PROFILE_IMPRESSION':
    'din LinkedIn-profil intryck',
  'report.analysis.explanation.docLabel.CV_REVIEW_CONSULT': 'din konsultprofil eller CV',
  'report.analysis.reviewed.title': 'Här är ditt underlag som vi tittat på',
  'report.analysis.reviewed.mobile.download.title': 'Öppna länken',
  'email.reportAvailable.subject': 'Din bedömning är klar!',
  'order.careerCalls.product.CAREER_CALL.next-step.title': 'Next step',
  'order.careerCalls.product.CAREER_CALL.find-standouts.title': 'Find standouts',
  'order.careerCalls.product.CAREER_CALL.get-the-job.title': 'Get the job',
  'order.careerCalls.product.CAREER_CALL.salary.title': 'Salary',
  'order.careerCalls.product.CAREER_CALL.network.title': 'Network',
  'order.careerCalls.assessmentInfo.contextSection.question1.CAREER_CALL.label':
    'Hur ser din nuvarande karriärsituation ut? Har du jobb? Är du student? Är du chef? Annat?',
  'order.careerCalls.assessmentInfo.contextSection.question1.CAREER_CALL.next-step.label':
    'Hur ser din nuvarande situation ut?',
  'order.careerCalls.assessmentInfo.contextSection.question1.CAREER_CALL.find-standouts.label':
    'Hur beskriver du dig själv idag?',
  'order.careerCalls.assessmentInfo.contextSection.question1.CAREER_CALL.get-the-job.label':
    'Hur ser din nuvarande situation ut?',
  'order.careerCalls.assessmentInfo.contextSection.question1.CAREER_CALL.salary.label':
    'Hur ser din nuvarande lönesituation ut?',
  'order.careerCalls.assessmentInfo.contextSection.question1.CAREER_CALL.platform.label':
    'Fråga 1',
  'order.careerCalls.assessmentInfo.contextSection.question1.CAREER_CALL.linkedin.label':
    'Hur ser din nuvarande situation ut?',
  'order.careerCalls.assessmentInfo.contextSection.question1.CAREER_CALL.network.label':
    'Hur ser din professionella plattform och ditt kontaktnät ut idag?',
  'order.careerCalls.assessmentInfo.contextSection.question1.BUSINESS_COACHING.label':
    'Hur ser din situation ut idag? Eller vad är din plan för den närmaste tiden?',
  'order.careerCalls.assessmentInfo.contextSection.question1.BUSINESS_COACHING.executive.label':
    'Hur ser din roll ut idag,? Var i organisationen befinner du dig?',
  'order.careerCalls.assessmentInfo.contextSection.question1.BUSINESS_COACHING.business.label':
    'Hur ser din verksamhet ut idag? Eller din plan för den närmaste tiden?',
  'order.careerCalls.assessmentInfo.contextSection.question1.COACHING.label':
    'Hur ser din nuvarande karriärsituation ut? Har du jobb? Är du student? Är du chef? Annat?',
  'order.careerCalls.assessmentInfo.contextSection.question1.COACHING.chef.label':
    'Vad har du för roll idag? Är det någonting i din nuvarande situation som är viktigt att veta?',
  'order.careerCalls.assessmentInfo.contextSection.question1.EXECUTIVE_COACHING.label':
    'Hur ser din roll ut idag? Var i organisationen befinner du dig?',
  'order.careerCalls.assessmentInfo.contextSection.question1.INTERVIEW_TRAINING_SESSION.label':
    'Hur tycker du att din senaste intervju gick?',
  'order.careerCalls.assessmentInfo.contextSection.question1.CAREER_CALL.campaign1.label':
    'Hur ser din nuvarande karriärsituation ut? Har du jobb? Är du student? Är du chef? Annat?',
  'order.careerCalls.assessmentInfo.contextSection.question1.CAREER_CALL.campaign2.label':
    'Hur ser din nuvarande karriärsituation ut? Har du jobb? Är du student? Är du chef? Annat?',
  'order.careerCalls.assessmentInfo.contextSection.question1.CAREER_CALL.grow.label':
    'Hur ser din nuvarande situation ut? Vilken typ av roll har du? Vilken typ av organisation jobbar du i?',
  'order.careerCalls.assessmentInfo.contextSection.question1.CAREER_CALL.erik':
    'Hur ser din nuvarande karriärsituation ut? Har du jobb? Är du student? Är du chef? Annat?',
  'order.careerCalls.assessmentInfo.contextSection.question1.CAREER_CALL.susanne':
    'Hur ser din nuvarande karriärsituation ut? Har du jobb? Är du student? Är du chef? Annat?',
  'order.careerCalls.assessmentInfo.contextSection.question2.CAREER_CALL.label':
    'Vad är din utmaning? Något specifikt ämne du vill prata om under samtalet?',
  'order.careerCalls.assessmentInfo.contextSection.question2.CAREER_CALL.next-step.label':
    'Vad saknar du i ditt nuvarande (eller föregående) jobb?',
  'order.careerCalls.assessmentInfo.contextSection.question2.CAREER_CALL.find-standouts.label':
    'Vad får du höra av andra att du är bra på?',
  'order.careerCalls.assessmentInfo.contextSection.question2.CAREER_CALL.get-the-job.label':
    'Hur söker du jobb idag?',
  'order.careerCalls.assessmentInfo.contextSection.question2.CAREER_CALL.salary.label':
    'Vad ser du att din utmaning är när det kommer till löneförhandling?',
  'order.careerCalls.assessmentInfo.contextSection.question2.CAREER_CALL.platform.label':
    'Fråga 2',
  'order.careerCalls.assessmentInfo.contextSection.question2.CAREER_CALL.likedin.label':
    'Vad är din utmaning?',
  'order.careerCalls.assessmentInfo.contextSection.question2.CAREER_CALL.network.label':
    'Vad är din utmaning?',
  'order.careerCalls.assessmentInfo.contextSection.question2.BUSINESS_COACHING.label':
    'Vad är din utmaning? Något specifikt ämne du vill prata om under samtalet?',
  'order.careerCalls.assessmentInfo.contextSection.question2.BUSINESS_COACHING.executive.label':
    'Vad är din utmaning? Något specifikt ämne du vill prata om under samtalet?',
  'order.careerCalls.assessmentInfo.contextSection.question2.BUSINESS_COACHING.business.label':
    'Vad är din utmaning? Något specifikt ämne du vill prata om under samtalet?',
  'order.careerCalls.assessmentInfo.contextSection.question2.COACHING.label':
    'Vad är din utmaning? Något specifikt ämne du vill prata om under samtalet?',
  'order.careerCalls.assessmentInfo.contextSection.question2.COACHING.chef.label':
    'Vad är din utmaning? Något specifikt ämne du vill prata med coachen om?',
  'order.careerCalls.assessmentInfo.contextSection.question2.EXECUTIVE_COACHING.label':
    'Vad är din utmaning? Något specifikt ämne du vill prata om under samtalet?',
  'order.careerCalls.assessmentInfo.contextSection.question2.INTERVIEW_TRAINING_SESSION.label':
    'Vad har du själv för bild av vad du behöver förbättra i din intervjuteknik? Kanske har du tidigare fått tips och råd som du tagit fasta på?',
  'order.careerCalls.assessmentInfo.contextSection.question2.CAREER_CALL.campaign1.label':
    'Vad är din utmaning? Vart vill du att 2019 ska ta dig?',
  'order.careerCalls.assessmentInfo.contextSection.question2.CAREER_CALL.campaign2.label':
    'Vad är din utmaning? Vad är det du vill kicka igång?',
  'order.careerCalls.assessmentInfo.contextSection.question2.CAREER_CALL.grow.label':
    'Vad har du för bild av hur du vill utvecklas?',
  'order.careerCalls.assessmentInfo.contextSection.question2.CAREER_CALL.erik':
    'Vad är din utmaning? Något specifikt ämne du vill prata om under samtalet?',
  'order.careerCalls.assessmentInfo.contextSection.question2.CAREER_CALL.susanne':
    'Vad är din utmaning? Något specifikt ämne du vill prata om under samtalet?',
  'order.careerCalls.assessmentInfo.contextSection.question3.CAREER_CALL.label':
    'Vad skulle du vilja få ut av samtalet med karriärexperten?',
  'order.careerCalls.assessmentInfo.contextSection.question3.CAREER_CALL.next-step.label':
    'Vad hoppas du din nästa tjänst ska innehålla?',
  'order.careerCalls.assessmentInfo.contextSection.question3.CAREER_CALL.find-standouts.label':
    'Vad upplever du som viktiga personliga egenskaper för den roll du söker?',
  'order.careerCalls.assessmentInfo.contextSection.question3.CAREER_CALL.get-the-job.label':
    'Vad är din utmaning? Vad vill du prata om under samtalet?',
  'order.careerCalls.assessmentInfo.contextSection.question3.CAREER_CALL.salary.label':
    'Vad har du åstadkommit på ditt nuvarande eller tidigare jobb, som du kan använda som argument?',
  'order.careerCalls.assessmentInfo.contextSection.question3.CAREER_CALL.platform.label':
    'Fråga 3',
  'order.careerCalls.assessmentInfo.contextSection.question3.CAREER_CALL.linkedin.label':
    'Vad skulle du vilja få ut av samtalet med karriärexperten?',
  'order.careerCalls.assessmentInfo.contextSection.question3.CAREER_CALL.network.label':
    'Vilka personer vill du nå eller komma i kontakt med?',
  'order.careerCalls.assessmentInfo.contextSection.question3.BUSINESS_COACHING.label':
    'Vad skulle du vilja få ut av samtalet med rådgivaren?',
  'order.careerCalls.assessmentInfo.contextSection.question3.BUSINESS_COACHING.executive.label':
    'Vad skulle du vilja få ut av samtalet med chefsrådgivaren?',
  'order.careerCalls.assessmentInfo.contextSection.question3.BUSINESS_COACHING.business.label':
    'Vad skulle du vilja få ut av samtalet med affärsrådgivaren?',
  'order.careerCalls.assessmentInfo.contextSection.question3.COACHING.label':
    'Vad skulle du vilja få ut av samtalet med coachen?',
  'order.careerCalls.assessmentInfo.contextSection.question3.COACHING.chef.label':
    'Vad skulle du vilja få ut av samtalet med coachen?',
  'order.careerCalls.assessmentInfo.contextSection.question3.EXECUTIVE_COACHING.label':
    'Vad skulle du vilja få ut av samtalet med chefsrådgivaren?',
  'order.careerCalls.assessmentInfo.contextSection.question3.INTERVIEW_TRAINING_SESSION.label':
    'Några frågor du tycker är extra svåra att besvara? Frågor som gör dig orolig eller som du är osäker på?',
  'order.careerCalls.assessmentInfo.contextSection.question3.CAREER_CALL.campaign1.label':
    'Vad skulle du vilja få ut av samtalet med karriärexperten?',
  'order.careerCalls.assessmentInfo.contextSection.question3.CAREER_CALL.campaign2.label':
    'Vad skulle du vilja få ut av samtalet med karriärexperten?',
  'order.careerCalls.assessmentInfo.contextSection.question3.CAREER_CALL.grow.label':
    'Vad är din utmaning? Något specifikt ämne du vill prata om under samtalet?',
  'order.careerCalls.assessmentInfo.contextSection.question3.CAREER_CALL.erik':
    'Vad skulle du vilja få ut av samtalet med Erik?',
  'order.careerCalls.assessmentInfo.contextSection.question3.CAREER_CALL.susanne':
    'Vad skulle du vilja få ut av samtalet med Susanne?',
  'order.careerCalls.assessmentInfo.contextSection.question4.CAREER_CALL.label':
    'Vad söker du för jobb? Vilket specifikt jobb, eller vilken typ av jobb eller bransch söker du?',
  'order.careerCalls.assessmentInfo.contextSection.question4.CAREER_CALL.next-step.label':
    'Vad skulle du vilja få ut av samtalet?',
  'order.careerCalls.assessmentInfo.contextSection.question4.CAREER_CALL.find-standouts.label':
    'Vad skulle du vilja få ut av samtalet?',
  'order.careerCalls.assessmentInfo.contextSection.question4.CAREER_CALL.get-the-job.label':
    'Vad skulle du vilja få ut av samtalet?',
  'order.careerCalls.assessmentInfo.contextSection.question4.CAREER_CALL.salary.label':
    'Vad skulle du vilja få ut av samtalet?',
  'order.careerCalls.assessmentInfo.contextSection.question4.CAREER_CALL.platform.label':
    'Fråga 4',
  'order.careerCalls.assessmentInfo.contextSection.question4.CAREER_CALL.network.label':
    'Vad skulle du vilja få ut av samtalet?',
  'order.careerCalls.assessmentInfo.contextSection.question4.COACHING.label':
    'Vad söker du för jobb? Vilket specifikt jobb, eller vilken typ av jobb eller bransch söker du?',
  'order.careerCalls.assessmentInfo.contextSection.question4.INTERVIEW_TRAINING_SESSION.label':
    'Baserat på den research du gjort om jobbet, vilka tror du att dina största utmaningar blir under intervjun?',
  'order.careerCalls.assessmentInfo.contextSection.question4.CAREER_CALL.grow.label':
    'Vad skulle du vilja få ut av samtalet med karriärexperten?',
  'order.careerCalls.assessmentInfo.contextSection.question4.CAREER_CALL.erik':
    'Vad söker du för jobb? Vilket specifikt jobb, eller vilken typ av jobb eller bransch söker du?',
  'order.careerCalls.assessmentInfo.contextSection.question4.CAREER_CALL.susanne':
    'Vad söker du för jobb? Vilket specifikt jobb, eller vilken typ av jobb eller bransch söker du?',
  'order.handle.call.complete.title': 'Call length',
  'order.handle.call.complete.btn': 'Complete',
  'order.handle.call.not.completed.title': 'Notes',
  'order.handle.call.not.completed.btn': 'Not Completed',
  'order.handle.call.validation.error': 'Field is required',
  'order.handle.call.cancel.reason': 'Call cancelled with reason:',
  'order.handle.call.completed.call.length': 'Call completed with length:',
};
