export default {
  brandName: 'Go Monday',
  domain: 'gomonday.se',
  domainBrand: 'GoMonday.se',
  contactEmailName: 'hello',
  raterAppName: 'Coffee',
  'edition.name.PRO': 'Professional',
  'edition.name.YOUNG_PRO': 'Young Professional',
  'edition.name.EXEC': 'Executive',
  'edition.name.CONSULT': 'Consultant',
  'edition.name.ACADEMIA': 'Researcher',
  'category.title.12': 'Present achievements and build credibility',
  'category.shortDesc.12':
    'The purpose of a CV is to confirm your skills and competence. It is therefore very important to demonstrate the results you have achieved.',
  'category.title.13': 'Ensure completeness and correctness',
  'category.shortDesc.13':
    'Your CV should give the employer a quick overview of your experiences and qualifications. It should be correctly written, short and to the point. There are also a number of factors concerning language and the structure of the document that affects how you are perceived as a person.',
  'category.title.14': 'Be relevant and targeted',
  'category.shortDesc.14':
    'An effective CV is relevant to the job it refers to in all its parts. This means that the CV has a common thread throughout it and all parts point clearly in the same direction.',
  'category.title.7': 'Highlight your potential',
  'category.shortDesc.7':
    'Focus more on your potential and what you can achieve in the future, rather than what you have done in the past.',
  'category.title.8': 'Focus on the employer',
  'category.shortDesc.8': 'Explain why you have chosen this specific employer and job.',
  'category.title.10': 'Present achievements and build credibility',
  'category.shortDesc.10':
    'Demonstrate with credibility what value you can bring to the job and the employer.',
  'category.title.11': 'Active, brief and correct',
  'category.shortDesc.11':
    'Meeting professional standards of correctness, neatness and language use is important. Meeting these standards demonstrates that you will be an employee who is respectful, organized and detail-oriented.',
  'category.title.16': 'Be relevant and targeted',
  'category.shortDesc.16':
    'The key to success among millions of profiles on LinkedIn is to be highly targeted and relevant. You need to illustrate where you are going and what you are looking for.',
  'category.title.17': 'Network and outreach',
  'category.shortDesc.17':
    'Having a LinkedIn profile is essential when applying for jobs or finding new clients. But to be successful, you need to be active. This category is all about how you can reach out with your profile.',
  'category.title.18': 'Complete and correct profile',
  'category.shortDesc.18':
    'Your LinkedIn profile should be correctly written and easy to grasp. In order to be found by recruiters and others who want to reach you, it is also important that the profile is complete and that you entered the correct information at the right place.',
  'category.title.20': 'Present achievements and build credibility',
  'category.shortDesc.20':
    "LinkedIn is the world's largest CV database where presenting your experiences and education in a good way is essential. To make connections and create job opportunities, you need to provide a credible picture of your background.",
  'category.title.21': "Make it clear that you'll enjoy the job",
  'category.shortDesc.21':
    'The questions in this category are primarily about the overall cultural match with the organization. The recruiter wants to make sure that you can get along with the team and that the conditions are right for you to feel at home and be able to grow.',
  'category.title.22': 'Show that you can do a great job',
  'category.shortDesc.22':
    'These questions focus primarily on the skills of the job. An experienced recruiter is looking for transferable skills, meaning skills you have developed in the past but can apply in a new context. Remember to decide on a few key messages that you return to on several occasions during the interview. What are the 2-3 most important reasons for why you are the right person for this job?',
  'category.title.23': 'Emphasize why you want to work here',
  'category.shortDesc.23':
    'Your skillset is really about everything that makes you good at doing something. From that perspective, willpower and interest are also considered skills. In addition to the formal merits and experiences, as well as your personal characteristics and strengths, it is important that you motivate why this position is the right job for you. Be specific when you explain why you are applying for this position and what attracts you to this particular organization.',
  'category.title.24': 'Personality and relevance',
  'category.shortDesc.24':
    'Each part of an effective portfolio needs to be relevant for the type of projects you are looking for as well as emphasize your talent in a way that makes you stand out.',
  'category.title.25': 'Projects and results',
  'category.shortDesc.25':
    'A portfolio is basically about confirming that you can achieve results in the projects you participate in. It is therefore very important that your descriptions are credible and provide a good picture of previous projects.',
  'category.title.26': 'Design and layout',
  'category.shortDesc.26':
    'A portfolio must have strong content. But in addition to this, your portfolio is also in itself a presentation of your visual abilities and precision. It needs to be properly written, have a good layout and show a visual identity.',
  'category.title.27': 'Convey your message',
  'order.INTERVIEW_TRAINING.question.qIntroduceYourself':
    'Tell me a little about yourself. ',
  'order.INTERVIEW_TRAINING.question.qCaltureWorkspace':
    'What culture would you like to see in a workplace?',
  'order.INTERVIEW_TRAINING.question.qCollaboration':
    'Tell me about a situation when collaboration between coworkers has been really important. Whar role did you take in the group? ',
  'order.INTERVIEW_TRAINING.question.qImportantEnjoyWork':
    'What is most important for you to enjoy your work? ',
  'order.INTERVIEW_TRAINING.question.qImportantFeatures':
    'What do you see as your most important personal qualities for this role?',
  'order.INTERVIEW_TRAINING.question.qWeaknesses':
    'Please mention and describe a few of your weaknesses. ',
  'order.INTERVIEW_TRAINING.question.qLearned':
    'What have you learned in your past experiences that you bring to with you to this new position?',
  'order.INTERVIEW_TRAINING.question.qWorkForUs':
    'What brings you to us, why do you want to work here?',
  'order.INTERVIEW_TRAINING.question.qKnowAboutUs': 'What do you know about us?',
  'order.INTERVIEW_TRAINING.question.qSeeYourself':
    'Where do you see yourself in five years?',
  'order.INTERVIEW_TRAINING.question.qAboutUs':
    'Do you have any questions about us or about the position?',
  'wellDone.comment.12': 'Very well done in this area!',
  'wellDone.comment.13':
    'Very well done in this area! You have a professional looking CV.',
  'wellDone.comment.14': 'Very well done in this area!',
  'wellDone.comment.7': 'Very well done in this area!',
  'wellDone.comment.8': 'Very well done in this area!',
  'wellDone.comment.10': 'Very well done in this area!',
  'wellDone.comment.11': 'Very well done in this area!',
  'wellDone.comment.16': 'Very well done in this area!',
  'wellDone.comment.17': 'Very well done in this area!',
  'wellDone.comment.18': 'Very well done in this area!',
  'wellDone.comment.20': 'Very well done in this area!',
  'wellDone.comment.21': 'Very well done in this area!',
  'wellDone.comment.22': 'Very well done in this area!',
  'wellDone.comment.23': 'Very well done in this area!',
  'wellDone.comment.24':
    'Well done in this area! It’s clear where your talent lies, and you have targeted your portfolio well.',
  'wellDone.comment.25':
    'Well done in this area! You present your experiences in a good and credible way and it is easy to understand what you have achieved.',
  'wellDone.comment.26':
    'Well done in this area! You have a nice-looking portfolio that is well-designed and easy to review.',
  'report.title': 'Your Report',
  'report.subtitle': 'Assessment',
  'report.overall': 'Overall comment',
  'report.was_created': 'Report was created by a job expert in: ',
  'report.understand': 'Understand your score',
  'report.open_link': 'View: ',
  'report.link': 'open document in new tab',
  'report.orderCreationDate.label': 'Created',
  'report.tabName.CV_REVIEW': 'CV Review',
  'report.tabName.COVER_LETTER_REVIEW': 'Cover Letter Review',
  'report.tabName.PORTFOLIO_REVIEW': 'Portfolio Review',
  'report.tabName.LINKEDIN_PROFILE_REVIEW': 'LinkedIn Review',
  'report.tabName.INTERVIEW_TRAINING': 'Interview Training',
  'report.tabNameSmallScreen.CV_REVIEW': 'CV',
  'report.tabNameSmallScreen.COVER_LETTER_REVIEW': 'Cover letter',
  'report.tabNameSmallScreen.PORTFOLIO_REVIEW': 'Portfolio',
  'report.tabNameSmallScreen.LINKEDIN_PROFILE_REVIEW': 'LinkedIn',
  'report.tabNameSmallScreen.INTERVIEW_TRAINING': 'Interview Training',
  'report.tabNameSmallScreen.LINKEDIN_PROFILE_CONSULT': 'LinkedIn',
  'report.tabNameSmallScreen.LINKEDIN_PROFILE_IMPRESSION': 'LinkedIn',
  'report.tabNameSmallScreen.CV_REVIEW_CONSULT': 'CV Consult',
  'report.unorderedAssessment.text':
    'You have not ordered this product. If you want to proceed, please place a new order.',
  'report.unorderedAssessment.orderBtn.text': 'Order a new assessment',
  'report.summary.title': 'Analysis of your document',
  'report.summary.documentLink.text': 'Original doc',
  'report.summary.score.label': 'Your C-Score',
  'report.summary.score.bar.label.weak': 'Weak',
  'report.summary.score.bar.label.good': 'Good',
  'report.summary.score.bar.label.excellent': 'Excellent',
  'report.summary.understandYourScore.title': 'Understand your score',
  'report.summary.understandYourScore.cScoreExplanation.text':
    "C-Score is a measurement of how good your application is on a scale from 0 to 100. <a href='https://gomonday.se/om-oss/var-metod' target='_blank'>Learn More</a>",
  'report.summary.understandYourScore.weak.text':
    'Very low chances of getting a job interview',
  'report.summary.understandYourScore.good.text':
    'Average changes of getting a job interview',
  'report.summary.understandYourScore.excellent.text':
    'High chances of getting a job interview',
  'report.summary.CV_REVIEW.0':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your CV. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest CVs we've assessed is <strong>{{averageScore}}</strong>. You have some work to do before we can recommend you to submit this job application. Please read our recommendations below to make a more elaborate version of your cv. You can make huge improvements quite easily if you follow them!",
  'report.summary.CV_REVIEW.25':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your CV. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest CVs we've assessed is <strong>{{averageScore}}</strong>. You should try again, because you still have some work to do before you have a job application that you will be proud to send to your potential employer. Please follow our recommendations below to get your cv to reach the level we think you want it to have!",
  'report.summary.CV_REVIEW.51':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your CV. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest CVs we've assessed is <strong>{{averageScore}}</strong>. You've done a good job and the result is quite ok, but you should work a bit further with your cv. Please follow our recommendations below so your application can become excellent!",
  'report.summary.CV_REVIEW.74':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your CV. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest CVs we've assessed is <strong>{{averageScore}}</strong>. You should be very proud of your work! But there's still some room for improvement. Consider our advice below - you are not far from having an exceptional CV!",
  'report.summary.CV_REVIEW.92':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your CV. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest CVs we've assessed is <strong>{{averageScore}}</strong>. Very well done! Your CV is really really good! It can't hurt, though, to take a look at the advice below to see if there is anything you can improve further. But you should be really proud of your work!",
  'report.summary.COVER_LETTER_REVIEW.0':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your Cover Letter.The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest Cover Letters we've assessed is <strong>{{averageScore}}</strong>. You have some work to do before we can recommend you to submit this job application. Please read our recommendations below to make a more elaborate version of your cover letter. You can make huge improvements quite easily if you follow them!",
  'report.summary.COVER_LETTER_REVIEW.25':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your Cover Letter. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest Cover Letters we've assessed is <strong>{{averageScore}}</strong>. You should try again, because you still have some work to do before you have a job application that you will be proud to send to your potential employer. Please follow our recommendations below to get your cover letter to reach the level we think you want it to have!",
  'report.summary.COVER_LETTER_REVIEW.51':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your Cover Letter. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest Cover Letters we've assessed is <strong>{{averageScore}}</strong>. You've done a good job and the result is quite ok, but you should work a bit further with your cover letter. Please follow our recommendations below so your application can become excellent!",
  'report.summary.COVER_LETTER_REVIEW.74':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your Cover Letter. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest Cover Letters we've assessed is <strong>{{averageScore}}</strong>. You should be very proud of your work! But there's still some room for improvement. Consider our advice below - you are not far from having an exceptional Cover Letter!",
  'report.summary.COVER_LETTER_REVIEW.92':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your Cover Letter. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest Cover Letters we've assessed is <strong>{{averageScore}}</strong>. Very well done! Your Cover Letter is really really good! It can't hurt, though, to take a look at the advice below to see if there is anything you can improve further. But you should be really proud of your work!",
  'report.summary.PORTFOLIO_REVIEW.0':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your Portfolio.The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest Portfolios we've assessed is <strong>{{averageScore}}</strong>.",
  'report.summary.PORTFOLIO_REVIEW.25':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your Portfolio. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest Portfolios we've assessed is <strong>{{averageScore}}</strong>.",
  'report.summary.PORTFOLIO_REVIEW.51':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your Portfolio. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest Portfolios we've assessed is <strong>{{averageScore}}</strong>.",
  'report.summary.PORTFOLIO_REVIEW.74':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your Portfolio. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest Portfolios we've assessed is <strong>{{averageScore}}</strong>.",
  'report.summary.PORTFOLIO_REVIEW.92':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your Portfolio. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest Portfolios we've assessed is <strong>{{averageScore}}</strong>.",
  'report.summary.LINKEDIN_PROFILE_REVIEW.0':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your LinkedIn Profile. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest profiles we've assessed is <strong>{{averageScore}}</strong>. You have some work to do to make your profile as good as it should be. Please read our recommendations below to make a more elaborate version of your LinkedIn Profile. You can make huge improvements quite easily if you follow them!",
  'report.summary.LINKEDIN_PROFILE_REVIEW.25':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your LinkedIn Profile. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest profiles we've assessed is <strong>{{averageScore}}</strong>. You should try again, because you still have some work to do before you have a LinkedIn profile to be of. Please follow our recommendations below to get your profile to reach the level we think you want it to have!",
  'report.summary.LINKEDIN_PROFILE_REVIEW.51':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your LinkedIn Profile. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest profiles we've assessed is <strong>{{averageScore}}</strong>. You've done a good job and the result is quite ok, but you should work a bit further with your LinkedIn Profile. Please follow our recommendations below so your application can become excellent!",
  'report.summary.LINKEDIN_PROFILE_REVIEW.74':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your LinkedIn Profile. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest profiles we've assessed is <strong>{{averageScore}}</strong>. You should be very proud of your work! But there's still some room for improvement. Consider our advice below - you are not far from having an exceptional LinkedIn Profile!",
  'report.summary.LINKEDIN_PROFILE_REVIEW.92':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your LinkedIn Profile. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest profiles we've assessed is <strong>{{averageScore}}</strong>. Very well done! Your LinkedIn Profile is really really good! It can't hurt, though, to take a look at the advice below to see if there is anything you can improve further. But you should be really proud of your work!",
  'report.summary.INTERVIEW_TRAINING.0':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your LinkedIn Profile. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest profiles we've assessed is <strong>{{averageScore}}</strong>. You have some work to do to make your profile as good as it should be. Please read our recommendations below to make a more elaborate version of your LinkedIn Profile. You can make huge improvements quite easily if you follow them!",
  'report.summary.INTERVIEW_TRAINING.25':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your LinkedIn Profile. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest profiles we've assessed is <strong>{{averageScore}}</strong>. You should try again, because you still have some work to do before you have a LinkedIn profile to be of. Please follow our recommendations below to get your profile to reach the level we think you want it to have!",
  'report.summary.INTERVIEW_TRAINING.51':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your LinkedIn Profile. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest profiles we've assessed is <strong>{{averageScore}}</strong>. You've done a good job and the result is quite ok, but you should work a bit further with your LinkedIn Profile. Please follow our recommendations below so your application can become excellent!",
  'report.summary.INTERVIEW_TRAINING.74':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your LinkedIn Profile. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest profiles we've assessed is <strong>{{averageScore}}</strong>. You should be very proud of your work! But there's still some room for improvement. Consider our advice below - you are not far from having an exceptional LinkedIn Profile!",
  'report.summary.INTERVIEW_TRAINING.92':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your LinkedIn Profile. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest profiles we've assessed is <strong>{{averageScore}}</strong>. Very well done! Your LinkedIn Profile is really really good! It can't hurt, though, to take a look at the advice below to see if there is anything you can improve further. But you should be really proud of your work!",
  'report.summary.LINKEDIN_PROFILE_IMPRESSION.0':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your LinkedIn Profile Impression. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest profiles we've assessed is <strong>{{averageScore}}</strong>. You have some work to do to make your profile as good as it should be. Please read our recommendations below to make a more elaborate version of your LinkedIn Profile Impression. You can make huge improvements quite easily if you follow them!",
  'report.summary.LINKEDIN_PROFILE_IMPRESSION.25':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your LinkedIn Profile Impression. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest profiles we've assessed is <strong>{{averageScore}}</strong>. You should try again, because you still have some work to do before you have a LinkedIn Profile Impression to be of. Please follow our recommendations below to get your profile to reach the level we think you want it to have!",
  'report.summary.LINKEDIN_PROFILE_IMPRESSION.51':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your LinkedIn Profile Impression. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest profiles we've assessed is <strong>{{averageScore}}</strong>. You've done a good job and the result is quite ok, but you should work a bit further with your LinkedIn Profile Impression. Please follow our recommendations below so your application can become excellent!",
  'report.summary.LINKEDIN_PROFILE_IMPRESSION.74':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your LinkedIn Profile Impression. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest profiles we've assessed is <strong>{{averageScore}}</strong>. You should be very proud of your work! But there's still some room for improvement. Consider our advice below - you are not far from having an exceptional LinkedIn Profile Impression!",
  'report.summary.LINKEDIN_PROFILE_IMPRESSION.92':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your LinkedIn Profile Impression. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest profiles we've assessed is <strong>{{averageScore}}</strong>. Very well done! Your LinkedIn Profile Impression is really really good! It can't hurt, though, to take a look at the advice below to see if there is anything you can improve further. But you should be really proud of your work!",
  'report.summary.CV_REVIEW_CONSULT.0':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your Consultant profile and CV. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest profiles we've assessed is <strong>{{averageScore}}</strong>. You have some work to do to make your profile as good as it should be. Please read our recommendations below to make a more elaborate version of your Consultant profile and CV. You can make huge improvements quite easily if you follow them!",
  'report.summary.CV_REVIEW_CONSULT.25':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your Consultant profile and CV. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest profiles we've assessed is <strong>{{averageScore}}</strong>. You should try again, because you still have some work to do before you have a Consultant profile and CV to be of. Please follow our recommendations below to get your profile to reach the level we think you want it to have!",
  'report.summary.CV_REVIEW_CONSULT.51':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your Consultant profile and CV. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest profiles we've assessed is <strong>{{averageScore}}</strong>. You've done a good job and the result is quite ok, but you should work a bit further with your Consultant profile and CV. Please follow our recommendations below so your application can become excellent!",
  'report.summary.CV_REVIEW_CONSULT.74':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your Consultant profile and CV. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest profiles we've assessed is <strong>{{averageScore}}</strong>. You should be very proud of your work! But there's still some room for improvement. Consider our advice below - you are not far from having an exceptional Consultant profile and CV!",
  'report.summary.CV_REVIEW_CONSULT.92':
    "You scored <strong>{{score}} out of 100</strong> in our assessment of your Consultant profile and CV. The average for the {{nbLastAssessmentsToTakeIntoAccount}} latest profiles we've assessed is <strong>{{averageScore}}</strong>. Very well done! Your Consultant profile and CV is really really good! It can't hurt, though, to take a look at the advice below to see if there is anything you can improve further. But you should be really proud of your work!",
  'report.question.INTERVIEW_TRAINING': 'Question',
  'report.answer.INTERVIEW_TRAINING': 'Answer',
  'report.analysis.title': 'This is what you need to focus on',
  'report.analysis.INTERVIEW_TRAINING.title': "Here's the basis of what we've reviewed",
  'report.analysis.explanation.text':
    'Our experts have looked at your {{docLabel}} to give you the best and most relevant feedback. <strong>Please follow and apply our recommendations below, to take it even further!</strong>',
  'report.analysis.explanation.docLabel.CV_REVIEW': 'CV',
  'report.analysis.explanation.docLabel.COVER_LETTER_REVIEW': 'cover letter',
  'report.analysis.explanation.docLabel.PORTFOLIO_REVIEW': 'portfolio',
  'report.analysis.explanation.docLabel.LINKEDIN_PROFILE_REVIEW': 'LinkedIn profile',
  'report.analysis.explanation.docLabel.INTERVIEW_TRAINING': 'Interview Training',
  'report.analysis.explanation.docLabel.LINKEDIN_PROFILE_IMPRESSION':
    'LinkedIn profile impression',
  'report.analysis.explanation.docLabel.CV_REVIEW_CONSULT': ' Consultant profile and CV',
  'report.analysis.reviewed.title': "Here's the basis of what we've reviewed",
  'report.analysis.reviewed.mobile.download.title': 'Open the link',
  'email.reportAvailable.subject': 'Your assessment report is ready!',
  'order.careerCalls.product.CAREER_CALL.next-step.title': 'Next step',
  'order.careerCalls.product.CAREER_CALL.find-standouts.title': 'Find standouts',
  'order.careerCalls.product.CAREER_CALL.get-the-job.title': 'Get the job',
  'order.careerCalls.product.CAREER_CALL.salary.title': 'Salary',
  'order.careerCalls.product.CAREER_CALL.network.title': 'Network',
  'order.careerCalls.assessmentInfo.contextSection.question1.CAREER_CALL.label':
    'What is your current career situation? Are you employed? Are you a student? Other?',
  'order.careerCalls.assessmentInfo.contextSection.question1.CAREER_CALL.next-step.label':
    'What is your current career situation? Are you employed? Are you a student? Other?',
  'order.careerCalls.assessmentInfo.contextSection.question1.CAREER_CALL.find-standouts.label':
    'How do you currently describe yourself?',
  'order.careerCalls.assessmentInfo.contextSection.question1.CAREER_CALL.get-the-job.label':
    'What is your current career situation? Are you employed? Are you a student? Other?',
  'order.careerCalls.assessmentInfo.contextSection.question1.CAREER_CALL.salary.label':
    'What is your current career situation? Are you employed? Are you a student? Other?',
  'order.careerCalls.assessmentInfo.contextSection.question1.CAREER_CALL.platform.label':
    '',
  'order.careerCalls.assessmentInfo.contextSection.question1.CAREER_CALL.network.label':
    'What does your professional platform and your network look like today?',
  'order.careerCalls.assessmentInfo.contextSection.question1.BUSINESS_COACHING.label':
    "What's your current situation? Or your plans for the near future?",
  'order.careerCalls.assessmentInfo.contextSection.question1.BUSINESS_COACHING.executive.label':
    'What is your current role and what responsibilities do you have?',
  'order.careerCalls.assessmentInfo.contextSection.question1.BUSINESS_COACHING.business.label':
    'What does your business look like today? Or what are you planning to do in the near future?',
  'order.careerCalls.assessmentInfo.contextSection.question1.COACHING.label':
    'What is your current career situation? Are you employed? Are you a student? Other?',
  'order.careerCalls.assessmentInfo.contextSection.question1.COACHING.chef.label': '',
  'order.careerCalls.assessmentInfo.contextSection.question1.EXECUTIVE_COACHING.label':
    'What is your role today? Where in the organization are you?',
  'order.careerCalls.assessmentInfo.contextSection.question1.INTERVIEW_TRAINING_SESSION.label':
    'How do you think you performed in your last interview?',
  'order.careerCalls.assessmentInfo.contextSection.question2.CAREER_CALL.label':
    'What is your challenge? Any specific topic you wish to talk about during the call?',
  'order.careerCalls.assessmentInfo.contextSection.question2.CAREER_CALL.next-step.label':
    'What are you missing in your current (or previous) job?',
  'order.careerCalls.assessmentInfo.contextSection.question2.CAREER_CALL.find-standouts.label':
    'What do other tell you you’re good at?',
  'order.careerCalls.assessmentInfo.contextSection.question2.CAREER_CALL.get-the-job.label':
    'How do you currently look for jobs?',
  'order.careerCalls.assessmentInfo.contextSection.question2.CAREER_CALL.salary.label':
    'What do you personally see as challenging in a salary negotiation?',
  'order.careerCalls.assessmentInfo.contextSection.question2.CAREER_CALL.platform.label':
    '',
  'order.careerCalls.assessmentInfo.contextSection.question2.CAREER_CALL.network.label':
    'What is your challenge? Any specific topic you wish to talk about during the call?',
  'order.careerCalls.assessmentInfo.contextSection.question2.BUSINESS_COACHING.label':
    'What challenge are you facing? Or what topic would you like to cover during our call?',
  'order.careerCalls.assessmentInfo.contextSection.question2.BUSINESS_COACHING.executive.label':
    'What challenge are you facing? Any specific topic you wish to talk about during this session?',
  'order.careerCalls.assessmentInfo.contextSection.question2.BUSINESS_COACHING.business.label':
    'What challenge are you facing? Any specific topic you wish to talk about during this session?',
  'order.careerCalls.assessmentInfo.contextSection.question2.COACHING.label':
    'What is your challenge? Any specific topic you wish to talk about during the call?',
  'order.careerCalls.assessmentInfo.contextSection.question2.COACHING.chef.label': '',
  'order.careerCalls.assessmentInfo.contextSection.question2.EXECUTIVE_COACHING.label':
    'What is your challenge? Any specific topic you wish to talk about during this session?',
  'order.careerCalls.assessmentInfo.contextSection.question2.INTERVIEW_TRAINING_SESSION.label':
    'In your own opinion, what do you need to improve on in your interview skills?',
  'order.careerCalls.assessmentInfo.contextSection.question3.CAREER_CALL.label':
    'What would you like to get out of this call with the career agent?',
  'order.careerCalls.assessmentInfo.contextSection.question3.CAREER_CALL.next-step.label':
    'What are you hoping to find in your next job?',
  'order.careerCalls.assessmentInfo.contextSection.question3.CAREER_CALL.find-standouts.label':
    'According to your experience, what personal characteristics are important in the role you’re looking for?',
  'order.careerCalls.assessmentInfo.contextSection.question3.CAREER_CALL.get-the-job.label':
    'What is your challenge? Any specific topic you wish to talk about during the call?',
  'order.careerCalls.assessmentInfo.contextSection.question3.CAREER_CALL.salary.label':
    'What have you achieved in your current or previous job, that you can use as argument in your salary negotiation?',
  'order.careerCalls.assessmentInfo.contextSection.question3.CAREER_CALL.platform.label':
    '',
  'order.careerCalls.assessmentInfo.contextSection.question3.CAREER_CALL.network.label':
    'What people are you interested in reaching och getting in contact with?',
  'order.careerCalls.assessmentInfo.contextSection.question3.BUSINESS_COACHING.label':
    'What outcome do you expect from your advisory session?',
  'order.careerCalls.assessmentInfo.contextSection.question3.BUSINESS_COACHING.executive.label':
    'What outcome do you expect from your advisory session?',
  'order.careerCalls.assessmentInfo.contextSection.question3.BUSINESS_COACHING.business.label':
    'What outcome do you expect from your advisory session?',
  'order.careerCalls.assessmentInfo.contextSection.question3.COACHING.label':
    'What would you like to get out of this call with the coach?',
  'order.careerCalls.assessmentInfo.contextSection.question3.COACHING.chef.label': '',
  'order.careerCalls.assessmentInfo.contextSection.question3.EXECUTIVE_COACHING.label':
    'What would you like to get out of this call with the executive adviser?',
  'order.careerCalls.assessmentInfo.contextSection.question3.INTERVIEW_TRAINING_SESSION.label':
    'Any questions you think are harder to answer? Questions that you worry about?',
  'order.careerCalls.assessmentInfo.contextSection.question4.CAREER_CALL.label':
    'What type of job are you looking for? What specific position, or type of job or industry, are you looking for?',
  'order.careerCalls.assessmentInfo.contextSection.question4.CAREER_CALL.next-step.label':
    'What would you like to get out from the call with the career agent?',
  'order.careerCalls.assessmentInfo.contextSection.question4.CAREER_CALL.find-standouts.label':
    'What would you like to get out from the call with the career agent?',
  'order.careerCalls.assessmentInfo.contextSection.question4.CAREER_CALL.get-the-job.label':
    'What would you like to get out from the call with the career agent?',
  'order.careerCalls.assessmentInfo.contextSection.question4.CAREER_CALL.salary.label':
    'What would you like to get out from the call with the career agent?',
  'order.careerCalls.assessmentInfo.contextSection.question4.CAREER_CALL.platform.label':
    '',
  'order.careerCalls.assessmentInfo.contextSection.question4.CAREER_CALL.network.label':
    'What would you like to get out from the call with the career agent?',
  'order.careerCalls.assessmentInfo.contextSection.question4.COACHING.label':
    'What type of job are you looking for? What specific position, or type of job or industry, are you looking for?',
  'order.careerCalls.assessmentInfo.contextSection.question4.INTERVIEW_TRAINING_SESSION.label':
    'Based on the research you have done about the job, what do you think is most important to get this position?',
  'order.handle.call.complete.title': 'Call length',
  'order.handle.call.complete.btn': 'Complete',
  'order.handle.call.not.completed.title': 'Notes',
  'order.handle.call.not.completed.btn': 'Not Completed',
  'order.handle.call.validation.error': 'Field is required',
  'order.handle.call.cancel.reason': 'Call cancelled with reason:',
  'order.handle.call.completed.call.length': 'Call completed with length:',
};
