import React, { ReactChild, ReactFragment, ReactPortal, ReactNode } from 'react';

import Container from 'components/Container';

import * as Sty from './SecondHeader.styles';

type Props = {
  children?:
    | ReactNode
    | ReactChild
    | ReactFragment
    | ReactPortal
    | boolean
    | null
    | undefined;
  title: string;
};

const SecondHeader: React.FC<Props> = props => {
  return (
    <Sty.SecondHeader>
      <Container>
        <Sty.Content>
          <h1>{props.title}</h1>
          {props.children && props.children}
        </Sty.Content>
      </Container>
    </Sty.SecondHeader>
  );
};

export default SecondHeader;
