import { createGlobalStyle } from 'styled-components';
import { Theme } from './themes/types';

export default createGlobalStyle`
  /* body,
  html {
    scroll-behavior: smooth;
  } */
  body {
    font-family: "Source Sans Pro", "Arial", sans-serif;

    background-color: #f8f9fb;
    color: ${props => (props.theme as Theme).colors.darkGrey};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-size: 14px;

    min-width: 320px;
  }
  // add similar classes for Modal styled divs
  .h2, .h3, .h4, .h5, .h6,
  h2, h3, h4, h5, h6 {
    color: ${props => (props.theme as Theme).colors.actionColor};
    line-height: 1.2;
    text-rendering: optimizelegibility;
    margin: 2em 0 1.2em;
    font-family: inherit;
    font-weight: 500;
  }

  h3 {
    font-size: 1.3em;
  }

  ul,
  ol {
    padding-left: 0;
    list-style-type: none;
  }

  p,
  span,
  li,
  a,
  label,
  blockquote {
    line-height: 1.75em;
  }

  /* Bootstrap Modal ↴ */
  .modal-title {
    margin: 0;
    line-height: 1.42857;
  }

  /* Bootstrap Button ↴ */
  .btn-group-lg > .btn, .btn-lg {
    font-size: 1rem;
  }

  .btn.disabled, .btn:disabled {
    opacity: 0.35;
    cursor: not-allowed;
  }

  .client-status {
    &-select {
      &-dropdown {
        .ant-select-item-option {
          color: blue;
        }
        &.status-select-green {
          color: rgb(210, 98, 62) !important;
        }
      }
      &-green {
        color: rgb(210, 98, 62) !important;
      }
      &-red {
        color: #5cb85c !important;
      }
    }
  }
`;
