import { LanguageCodes } from 'models/common';
import { Service } from 'models/service';

export enum AccountRoles {
  admin = 'admin',
  customer = 'customer',
  rater = 'rater',
  assignee = 'assignee',
}

export interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  lang: LanguageCodes;
  role: AccountRoles;
  services: Service[];
}

export const PASSWORD_COMPLEXITY_REGEXP = /^(?=.*\d)(?=.*[a-zäöåéA-ZÄÖÅ]).{6,24}$/;
