import * as React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import Modal from 'components/Modal';
import ChangePasswordForm from './ChangePasswordForm';

import { handleApolloError } from 'services/errorService';

const CHANGE_PASSWORD = gql`
  mutation ($oldPassword: String!, $password: String!) {
    changeMyPassword(oldPassword: $oldPassword, password: $password)
  }
`;

type ChangePasswordModalProps = {
  onClose: () => void;
  onMessage: (newToast: any) => void;
  isChangePasswordModalShown: boolean;
};

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  onClose,
  onMessage,
  isChangePasswordModalShown = false,
}) => {
  const [isError, setError] = React.useState(false);
  const [changePassReq, { loading }] = useMutation(CHANGE_PASSWORD, {
    onCompleted: data => {
      onMessage({
        header: 'Success',
        text: 'Password was successfully changed',
      });
      onClose();
    },

    onError: err => {
      handleApolloError(err);
      onMessage({
        header: 'Error',
        isError: true,
        text: err.message,
      });
      setError(true);
    },
  });

  const handleSubmit = React.useCallback(
    sets => {
      if (sets.password === sets.passwordConfirm) {
        changePassReq({
          variables: sets,
        });
        setError(false);
      }

      setError(true);
    },
    [changePassReq],
  );

  return (
    <Modal show={isChangePasswordModalShown} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title as={'h3'}>Change your password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ChangePasswordForm
          onSubmit={handleSubmit}
          isError={isError}
          isLoading={loading}
          handleCloseClick={onClose}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ChangePasswordModal;
