export const getFromLocalStorage = (key: string) => {
  const data = localStorage.getItem(key);

  if (data !== null) {
    try {
      return JSON.parse(data);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.error(error);
    }
  }

  return null;
};

export const saveInLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};
