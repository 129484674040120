import React from 'react';
import moment from 'moment';
import { ThemeProvider } from 'styled-components';

import DefaultTheme from 'styles/themes/defaultTheme/DefaultTheme';
import CssReset from 'styles/CssReset';

import Header from 'containers/Header';
import Routes from 'routes';
import Toast from '../../components/Toast';

// Monday is the first day of the week
moment.updateLocale('en', {
  week: {
    dow: 1,
    doy: 4,
  },
});

const App = () => {
  const [toast, pushToast] = React.useState();
  const onMessage = (newToast: any) => {
    pushToast(newToast);
  };

  return (
    <ThemeProvider theme={DefaultTheme}>
      <div data-testid="app">
        <CssReset />
        <Header onMessage={onMessage} />
        <Routes />
        <Toast toast={toast} />
      </div>
    </ThemeProvider>
  );
};

export default App;
