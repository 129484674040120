import { css } from 'styled-components';

/**
 * Take breakpoints config and return media query
 * functions ready to use in styles
 *
 * @param {Object} b Breakpoints config object
 */
export const getMediaQuery = b => {
    const objWithBreakpointsFuncs = {};

    Object.keys(b).forEach(
        breakpointName =>
            (objWithBreakpointsFuncs[breakpointName] = (...args) =>
                css`
                    @media (min-width: ${b[breakpointName]}px) {
                        ${css(...args)};
                    }
                `),
    );

    return objWithBreakpointsFuncs;
};
