import ApolloClient, { InMemoryCache } from 'apollo-boost';

import { typeDefs, resolvers, GET_AUTHORIZED, GET_ACCOUNT } from './resolvers';

import { env, localStorageKeys } from 'globalVariables';
import { getFromLocalStorage, removeFromLocalStorage } from 'services/browser';

const createHeaders = (token?: string) => {
  const headers: any = {};

  if (token) {
    headers.Authorization = token ? `Bearer ${token}` : '';
  }

  return headers;
};

const uri = `${env.apiUrl}${process.env.REACT_APP_API_PATH}`;

const cache = new InMemoryCache();

const storedToken = getFromLocalStorage(localStorageKeys.token);
const account = getFromLocalStorage(localStorageKeys.account);

cache.writeData({
  data: {
    isAuthorized: Boolean(storedToken),
    account,
    // assessments,
  },
});

const client = new ApolloClient({
  uri,
  headers: createHeaders(storedToken),
  cache,
  resolvers,
  typeDefs,
  request: async operation => {
    const token = await getFromLocalStorage(localStorageKeys.token);
    operation.setContext({
      headers: createHeaders(token),
    });
  },
  onError: ({ graphQLErrors }) => {
    const unauthorizedError = graphQLErrors?.some(({ extensions }) => {
      return extensions?.originalError?.statusCode === 401;
    });

    if (unauthorizedError) {
      cache.writeQuery({
        query: GET_AUTHORIZED,
        data: { isAuthorized: false },
      });

      cache.writeQuery({
        query: GET_ACCOUNT,
        data: { account: null },
      });

      removeFromLocalStorage(localStorageKeys.token);
      removeFromLocalStorage(localStorageKeys.account);
    }
  },
});

export default client;
