import * as React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import Modal from 'components/Modal';
import AddUserForm from './AddUserForm';

import { handleApolloError } from 'services/errorService';

const ADD_NEW_USER = gql`
  mutation (
    $email: String!
    $password: String!
    $firstName: String!
    $linkedinUrl: String
    $lang: UserLang!
    $role: UserRole!
  ) {
    addUser(
      user: {
        email: $email
        password: $password
        firstName: $firstName
        image: $linkedinUrl
        lang: $lang
        role: $role
      }
    ) {
      id
      firstName
      role
    }
  }
`;

type AddUserModalProps = {
  onClose: () => void;
  onMessage: (newToast: any) => void;
  isAddUserModalShown: boolean;
};

const AddUserModal: React.FC<AddUserModalProps> = ({
  onClose,
  onMessage,
  isAddUserModalShown = false,
}) => {
  const [isError, setError] = React.useState(false);
  const [addUser, { loading }] = useMutation(ADD_NEW_USER, {
    onCompleted: data => {
      onMessage({
        header: 'Success',
        text: 'User "' + data.addUser.firstName + '" was successfully added',
      });
      onClose();
    },
    onError: err => {
      handleApolloError(err);
      onMessage({
        header: 'Error',
        isError: true,
        text: err.message,
      });
      setError(true);
    },
  });

  const handleSubmit = React.useCallback(
    sets => {
      if (sets.password === sets.passwordConfirm) {
        addUser({
          variables: sets,
        });
        setError(false);
      }

      setError(true);
    },
    [addUser],
  );

  return (
    <Modal show={isAddUserModalShown} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title as={'h3'}>Add new User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddUserForm
          onSubmit={handleSubmit}
          isError={isError}
          isLoading={loading}
          handleCloseClick={onClose}
        />
      </Modal.Body>
    </Modal>
  );
};

export default AddUserModal;
