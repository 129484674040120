import * as React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Form from 'components/Form';
import Button from 'components/Button';

import * as Sty from './AddUserForm.styles';
import { PASSWORD_COMPLEXITY_REGEXP } from 'models/user';

enum UserLang {
  ENGLISH = 'ENGLISH',
  SWEDISH = 'SWEDISH',
}

enum UserRole {
  RATER = 'RATER',
  AGENT = 'AGENT',
  ASSIGNEE = 'ASSIGNEE',
}

interface FormState {
  email: string;
  firstName: string;
  linkedinUrl: string;
  password: string;
  passwordConfirm: string;
  lang: UserLang;
  role: UserRole;
}

const initialValues: FormState = {
  email: '',
  firstName: '',
  linkedinUrl: '',
  password: '',
  passwordConfirm: '',
  lang: UserLang.SWEDISH,
  role: UserRole.ASSIGNEE,
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Can you please double-check that address, it doesn't seem valid")
    .required("Can you please double-check that address, it doesn't seem valid"),
  firstName: Yup.string().required("Name can't be empty"),
  password: Yup.string()
    .required('Password field is required')
    .min(6, 'Password must be at least 6 characters')
    .max(24, 'Password must be at most 24 characters')
    .matches(
      PASSWORD_COMPLEXITY_REGEXP,
      'The password needs to contain at least one number and one letter.',
    )
    .test(
      'password',
      'New password should not be the same as the old one',
      function (value) {
        return value !== this.parent.oldPassword;
      },
    ),
  passwordConfirm: Yup.string()
    .required('Confirm password field is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});

type Props = {
  onSubmit: (sets: FormState) => void;
  isError: boolean;
  isLoading: boolean;
  handleCloseClick: () => void;
};

const AddUserForm: React.FC<Props> = ({
  isError,
  isLoading,
  onSubmit,
  handleCloseClick,
}) => {
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    resetForm,
    handleChange,
    handleSubmit,
    handleBlur,
  } = useFormik({ initialValues, onSubmit, validationSchema });

  React.useEffect(() => {
    if (isError) {
      resetForm();
    }
  }, [isError, resetForm]);

  const handleFormSubmit = React.useCallback(
    (ev: React.FormEvent<HTMLElement>) => {
      handleSubmit(ev as React.FormEvent<HTMLFormElement>);
    },
    [handleSubmit],
  );

  return (
    <Form noValidate onSubmit={handleFormSubmit}>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>E-mail address</Form.Label>
        <Form.Control
          name="email"
          type="text"
          placeholder="your@email.com"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={!!errors.email && touched.email}
          required
        />
        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formBasicName">
        <Form.Label>Name and surname</Form.Label>
        <Form.Control
          name="firstName"
          type="text"
          placeholder="John Doe"
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={!!errors.firstName && touched.firstName}
          required
        />
        <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formBasicLiUrl">
        <Form.Label>Image (URL to Linkedin)</Form.Label>
        <Form.Control
          name="linkedinUrl"
          type="text"
          placeholder="http://..."
          value={values.linkedinUrl}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={!!errors.linkedinUrl && touched.linkedinUrl}
        />
        <Form.Control.Feedback type="invalid">{errors.linkedinUrl}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Form.Group} controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control
          name="password"
          type="password"
          placeholder="Password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={!!errors.password && touched.password}
          required
        />
        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Form.Group} controlId="formBasicPasswordConfirm">
        <Form.Label>Password</Form.Label>
        <Form.Control
          name="passwordConfirm"
          type="password"
          placeholder="Confirm password"
          value={values.passwordConfirm}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={
            !!errors.passwordConfirm &&
            touched.passwordConfirm &&
            errors.password !== errors.passwordConfirm
          }
          required
        />
        <Form.Control.Feedback type="invalid">
          {errors.passwordConfirm}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formBasicRole">
        <Form.Label>Select Role</Form.Label>
        <Form.Control name="role" as="select" onChange={handleChange}>
          <option value={UserRole.ASSIGNEE}>Assignee</option>
          <option value={UserRole.RATER}>Rater</option>
          <option value={UserRole.AGENT}>Agent</option>
        </Form.Control>
      </Form.Group>
      <Sty.ButtonsBlock>
        <Button variant="outline-info" onClick={handleCloseClick}>
          Cancel
        </Button>
        <Button
          variant="success"
          type="submit"
          loading={isLoading}
          disabled={!(isValid && dirty)}
        >
          Confirm
        </Button>
      </Sty.ButtonsBlock>
    </Form>
  );
};

export default AddUserForm;
