import { gql, Resolvers, ApolloClient } from 'apollo-boost';
import { ApolloCache } from 'apollo-cache';

export const typeDefs = gql`
  type Comment {
    id: Int!
    categoryId: Int!
    greenText: String!
    redText: String!
    points: Int!
    isGrouped: Boolean!
    productCode: OrderProductCode!
  }

  type ListOfComments {
    productCode: String!
    comments: [Comment]!
  }

  type Assessment {
    orderId: Int!
    listComments: [ListOfComments]!
    timestamp: Int
  }

  extend type Query {
    isAuthorized: Boolean!
    account: User!
  }

  extend type Mutation {
    setAuthorized(isAuthorized: Boolean!): Boolean!
    setAccount(user: User): User
    clearAccount: ID!
  }
`;

export const GET_AUTHORIZED = gql`
  query {
    isAuthorized @client
  }
`;

export const GET_ACCOUNT = gql`
  query {
    account @client {
      username
    }
  }
`;

type ResolverFn = (
  parent: any,
  args: any,
  context: {
    cache: ApolloCache<any>;
    client: ApolloClient<any>;
  },
) => any;

interface ResolverMap {
  [field: string]: ResolverFn;
}

interface AppResolvers extends Resolvers {
  Mutation: ResolverMap;
  Query: ResolverMap;
}

export const resolvers: AppResolvers = {
  Mutation: {
    setAuthorized: (_, { isAuthorized }, { cache }): boolean => {
      cache.writeQuery({
        query: GET_AUTHORIZED,
        data: { isAuthorized },
      });

      return isAuthorized;
    },
    setAccount: (_, args, { cache }): string => {
      cache.writeQuery({
        query: GET_ACCOUNT,
        data: { account: args.user },
      });

      return args.user;
    },
    clearAccount: (_, args, { cache }) => {
      cache.writeQuery({
        query: GET_ACCOUNT,
        data: { account: null },
      });

      return null;
    },
  },
  Query: {},
};
