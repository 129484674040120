import * as React from 'react';

type Dimensions = {
  width: number;
  height: number;
};

const useScreenSize = (): Dimensions => {
  const isClient = typeof window === 'object';

  const getSize = React.useCallback(
    () => ({
      width: isClient ? window.innerWidth : 0,
      height: isClient ? window.innerHeight : 0,
    }),
    [isClient],
  );

  const [windowSize, setWindowSize] = React.useState<Dimensions>(getSize);

  React.useEffect((): any => {
    if (!isClient) {
      return null;
    }

    const handleResize = () => {
      setWindowSize(getSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getSize, isClient]);

  return windowSize;
};

export default useScreenSize;
