import styled from 'styled-components/macro';

export const ButtonsBlock = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin: 0 10px;
  }
`;
