export type ColorTypes = {
  actionColor: string;
  darkGrey: string;
  gradientLightGrey: string;
  wellBorderColor: string;
  iconBtnActiveColor: string;
  iconBtnHoverColor: string;
  statsPanelGrey: string;
  greenCommentBg: string;
  redCommentBg: string;
  skyBlue: string;
  youngProEditionColor: string;
  execEditionColor: string;
  consultEditionColor: string;
  academiaEditionColor: string;
  brandDanger: string;
  grey: string;
};

const colors: ColorTypes = {
  actionColor: '#1C7FA8',
  darkGrey: '#4c5365',
  gradientLightGrey: '#F1F2F5',
  wellBorderColor: '#BDCDD8',
  iconBtnActiveColor: '#BDCDD8',
  iconBtnHoverColor: 'black',
  statsPanelGrey: '#575757',
  greenCommentBg: '#CFF8D8',
  redCommentBg: '#FFBEBE',
  skyBlue: '#26A9E0',
  youngProEditionColor: '#33B8B0',
  execEditionColor: '#9F2779',
  consultEditionColor: '#DB8F25',
  academiaEditionColor: '#A2D628',
  brandDanger: '#D2623E',
  grey: '#8f9194',
};

export default colors;
