import { getMediaQuery } from '../utils/utils';

import colors from './configs/colors';
import Breakpoints from './configs/Breakpoints';
import variables from './configs/variables';

const mediaQuery = getMediaQuery(Breakpoints);

export default {
  colors,
  mediaQuery,
  variables,
};
