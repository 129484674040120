import * as React from 'react';
import PopoverLib from 'react-bootstrap/Popover';
import Overlay, { Placement } from 'react-bootstrap/Overlay';
import { assignRef } from 'use-callback-ref';
import ReactDOM from 'react-dom';

type PropTypes = {
  id?: React.ReactText;
  content: React.ReactNode;
  place?: Placement;
  children: React.ReactNode;
  onEntered?: () => void;
  onExited?: () => void;
};

export type PopoverRefType = {
  hide: () => void;
};

class RefHolder extends React.Component {
  render() {
    return this.props.children;
  }
}

const Popover: React.ForwardRefRenderFunction<PopoverRefType, PropTypes> = (
  { id, content, place, children, onEntered, onExited },
  ref,
) => {
  const targetRef = React.useRef<RefHolder>(null);

  const [isShown, setShown] = React.useState(false);

  const handleEntered = React.useCallback(() => {
    if (onEntered) {
      onEntered();
    }
  }, [onEntered]);

  const handleExited = React.useCallback(() => {
    setShown(false);
    if (onExited) {
      onExited();
    }
  }, [onExited]);

  const handleTargetClick = React.useCallback(() => {
    setShown(true);
  }, []);

  const handleHide = React.useCallback(() => {
    setShown(false);
  }, []);

  const getTarget = React.useCallback(
    () => ReactDOM.findDOMNode(targetRef?.current) as HTMLElement,
    [targetRef],
  );

  const targetProps = React.useMemo(
    () => ({
      onClick: handleTargetClick,
    }),
    [handleTargetClick],
  );

  React.useEffect(() => {
    if (ref) {
      assignRef(ref, {
        hide: () => setShown(false),
      });
    }
  }, [ref]);

  return (
    <>
      <RefHolder ref={targetRef}>
        {React.cloneElement(
          React.Children.only(children) as React.DetailedReactHTMLElement<
            {},
            HTMLElement
          >,
          targetProps,
        )}
      </RefHolder>
      <Overlay
        rootClose
        show={isShown}
        target={getTarget}
        placement={place || 'auto'}
        onEntered={handleEntered}
        onExited={handleExited}
        onHide={handleHide}
      >
        <PopoverLib id={(id as string) || 'popover'}>{content}</PopoverLib>
      </Overlay>
    </>
  );
};

export default React.forwardRef(Popover);
