export enum localStorageKeys {
  token = 'token',
  account = 'account',
  assessments = 'assessments',
  currentlyAssessedDoc = 'currentlyAssessedDoc',
}

type EnvPropTypes = {
  apiUrl: string;
  apiPath: string;
  userAppUrl: string;
  gmtId: string;
};

export const env: EnvPropTypes = {
  apiUrl: process.env.REACT_APP_API_URL!,
  apiPath: process.env.REACT_APP_API_PATH!,
  userAppUrl: process.env.REACT_APP_USER_APP_URL!,
  gmtId: process.env.REACT_APP_GTM_ID!,
};

// TODO: make it dynamic
export const HEADER_HEIGHT = 165;

export const PREFERRED_CATEGORY_ORDER = {
  COVER_LETTER_REVIEW: [7, 8, 10, 11],
  CV_REVIEW: [14, 12, 13],
  INTERVIEW_TRAINING: [21, 22, 23],
  LINKEDIN_PROFILE_REVIEW: [16, 17, 18, 20],
  PORTFOLIO_REVIEW: [24, 25, 26],
} as any;
