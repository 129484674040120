import styled from 'styled-components/macro';

export const Overly = styled.div<{ fixed?: boolean }>`
  position: ${props => (props.fixed ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 1;
`;
